import { useQuery } from "@tanstack/react-query";

import { getServicesBySearch } from "@/services/categoryServices";
import { useDebounce } from "@/hooks/useDebounce";

type useSearchServicesParams = {
  appId: number;
  query: string;
  aggregatorOnly?: string | null;
  aggregatorExcept?: string | null;
};

export function useSearchServices({
  appId,
  query,
  aggregatorOnly,
  aggregatorExcept,
}: useSearchServicesParams) {
  const queryDeffered = useDebounce(query, 700);

  return useQuery({
    queryKey: ["services-search", appId, queryDeffered, aggregatorOnly, aggregatorExcept],
    queryFn: () =>
      getServicesBySearch({ appId, query: queryDeffered, aggregatorOnly, aggregatorExcept }),
    enabled: !!appId && !!query,
  });
}
