import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAtom, useSetAtom } from "jotai";
import dayjs from "dayjs";
import { AxiosError } from "axios";
import { useQueryClient } from "@tanstack/react-query";
import { Text } from "@astrolabe-ui/react";
import { NotePencil } from "@phosphor-icons/react";

import { Button } from "@/components";
import { CardInfo } from "../../../components/card-info";
import { SuccessCreateScheduling } from "@/pages/schedulings/components/success-create-scheduling";
import { useSearchParams } from "@/hooks/useSearchParams";
import { useAppId } from "@/store/useAppStore";
import { useCreateScheduling } from "../hooks/use-create-scheduling";
import { formatCpf, formatPhone } from "@/utils/formats";
import { modalErrorSchedulingAtom } from "@/pages/schedulings/components/modal-error";
import { createSchedulingDataAtom } from "../atoms/create-scheduling-data-atom";

export function ValidationSchedule() {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const searchParams = useSearchParams();
  const navigation = useHistory();
  const queryClient = useQueryClient();

  const appId = useAppId();

  const [showModalSuccess, setShowModalSuccess] = useState({ isOpen: false, id: 0 });

  const { mutateAsync, isLoading } = useCreateScheduling();

  const [
    { personal, resource, questions, extraFields, local, schedule, hour },
    setCreateSchedulingData,
  ] = useAtom(createSchedulingDataAtom);

  const setModalErrorData = useSetAtom(modalErrorSchedulingAtom);

  async function handleEnterToQueue() {
    if (resource && personal && local && schedule && hour) {
      await mutateAsync(
        {
          appId: Number(appId),
          schedulingId: Number(id),
          resourceId: resource.id,
          localId: local.id,
          scheduleId: schedule.id,
          hourId: hour.id,
          personal,
          extraFields,
          questions,
        },
        {
          onSuccess: ({ id }) => {
            queryClient.invalidateQueries({ queryKey: ["my-schedulings", appId] });

            setShowModalSuccess({ isOpen: true, id });
          },
          onError: (err) => {
            console.error(err);

            let title = "Não foi possível agendar";
            let message = "Não conseguimos realizar o agendamento no serviço desejado.";
            let enableGoSchedulings = false;

            if (err instanceof AxiosError && err.response?.data?.type === "sem_vagas_data") {
              title = "A data não está mais disponível";
              message = "Selecione outra data para continuar";
              handleChangeStep("days");
            }

            if (err instanceof AxiosError && err.response?.data?.type === "sem_vagas_hora") {
              title = "O horário não está mais disponível";
              message = "Selecione outro horário para continuar";
              handleChangeStep("local");
            }

            if (err instanceof AxiosError && err.response?.data?.type === "cpf_agendado") {
              title = "Esse CPF já possui um agendamento";
              message = "O CPF possui um agendamento cadastrado no serviço solicitado";
            }

            if (err instanceof AxiosError && err.response?.data?.type === "cpf_fila") {
              title = "Esse CPF já está cadastrado na fila";
              message = "A pessoa com esse CPF já tem posição na fila no serviço desejado";
              enableGoSchedulings = true;
            }

            setModalErrorData({
              isOpen: true,
              enableGoSchedulings,
              title,
              message,
            });
          },
        },
      );
    }
  }

  function handleChangeStep(newStep: string) {
    searchParams.delete("step");

    searchParams.append("step", newStep);

    navigation.push({ pathname: "", search: searchParams.toString() });
  }

  function handleEditQuestion(id: number) {
    const index = questions.findIndex((item) => item.id === id);

    searchParams.delete("step");
    searchParams.append("step", "questions");
    searchParams.append("question", String(index));

    navigation.push({ pathname: "", search: searchParams.toString() });
  }

  function clearSchedulingData() {
    setCreateSchedulingData({
      resource: null,
      personal: null,
      schedule: null,
      hour: null,
      local: null,
      questions: [],
      extraFields: [],
    });
  }

  function handleCloseModal() {
    setShowModalSuccess({ isOpen: false, id: 0 });
  }

  return (
    <div className="flex w-full flex-1 flex-col gap-5 pb-40 pt-6">
      <Text
        asChild
        size="lg"
        weight="medium"
        color="primary-500"
        className="px-4 text-[24px] leading-[28px]"
      >
        <h1>Confirme os detalhes do seu agendamento</h1>
      </Text>

      <div className="flex flex-col gap-4 px-4">
        {schedule ? (
          <CardInfo.Root>
            <CardInfo.Title>Dia do agendamento</CardInfo.Title>

            <CardInfo.Info>
              <CardInfo.InfoTitle>Data</CardInfo.InfoTitle>
              <CardInfo.InfoText>{dayjs(schedule.date).format("DD/MM/YYYY")}</CardInfo.InfoText>
            </CardInfo.Info>

            <CardInfo.Action onClick={() => handleChangeStep("days")}>
              <Text size="xs" color="primary-500">
                Editar
              </Text>
              <NotePencil size={12} />
            </CardInfo.Action>
          </CardInfo.Root>
        ) : null}

        {hour ? (
          <CardInfo.Root>
            <CardInfo.Title>Horário do agendamento</CardInfo.Title>

            <CardInfo.Info>
              <CardInfo.InfoTitle>Horário</CardInfo.InfoTitle>
              <CardInfo.InfoText>{hour.hour}</CardInfo.InfoText>
            </CardInfo.Info>

            <CardInfo.Action onClick={() => handleChangeStep("hours")}>
              <Text size="xs" color="primary-500">
                Editar
              </Text>
              <NotePencil size={12} />
            </CardInfo.Action>
          </CardInfo.Root>
        ) : null}

        {local ? (
          <CardInfo.Root key={local.id}>
            <CardInfo.Title>Local</CardInfo.Title>

            <CardInfo.Info>
              <CardInfo.InfoTitle>Local de atendimento</CardInfo.InfoTitle>
              <CardInfo.InfoText>{local.name}</CardInfo.InfoText>
            </CardInfo.Info>
            <CardInfo.Info>
              <CardInfo.InfoTitle>Endereço</CardInfo.InfoTitle>
              <CardInfo.InfoText>{local.address.address}</CardInfo.InfoText>
            </CardInfo.Info>

            <CardInfo.Action onClick={() => handleChangeStep("local")}>
              <Text size="xs" color="primary-500">
                Editar
              </Text>
              <NotePencil size={12} />
            </CardInfo.Action>
          </CardInfo.Root>
        ) : null}

        {resource ? (
          <CardInfo.Root>
            <CardInfo.Title>Serviço</CardInfo.Title>

            <CardInfo.Info>
              <CardInfo.InfoTitle>Serviço</CardInfo.InfoTitle>
              <CardInfo.InfoText>{resource.name}</CardInfo.InfoText>
            </CardInfo.Info>

            <CardInfo.Action onClick={() => handleChangeStep("resource")}>
              <Text size="xs" color="primary-500">
                Editar
              </Text>
              <NotePencil size={12} />
            </CardInfo.Action>
          </CardInfo.Root>
        ) : null}

        {personal ? (
          <CardInfo.Root>
            <CardInfo.Title>Dados pessoais</CardInfo.Title>

            <CardInfo.Info>
              <CardInfo.InfoTitle>Nome</CardInfo.InfoTitle>
              <CardInfo.InfoText>{personal.name}</CardInfo.InfoText>
            </CardInfo.Info>

            <CardInfo.Info>
              <CardInfo.InfoTitle>CPF</CardInfo.InfoTitle>
              <CardInfo.InfoText>{formatCpf(personal.cpf)}</CardInfo.InfoText>
            </CardInfo.Info>

            <CardInfo.Info>
              <CardInfo.InfoTitle>Celular</CardInfo.InfoTitle>
              <CardInfo.InfoText>{formatPhone(personal.phone)}</CardInfo.InfoText>
            </CardInfo.Info>

            {personal.email ? (
              <CardInfo.Info>
                <CardInfo.InfoTitle>E-mail</CardInfo.InfoTitle>
                <CardInfo.InfoText>{personal.email}</CardInfo.InfoText>
              </CardInfo.Info>
            ) : null}

            {extraFields.map((item) => (
              <CardInfo.Info key={item.id}>
                <CardInfo.InfoTitle>{item.title}</CardInfo.InfoTitle>

                {item.type === "arquivo" ? (
                  <CardInfo.InfoFile>{item.response}</CardInfo.InfoFile>
                ) : null}

                {item.type === "imagem" ? (
                  <CardInfo.InfoImage src={item.response.toString()} />
                ) : null}

                {item.type !== "imagem" && item.type !== "arquivo" ? (
                  <CardInfo.InfoText>{item.response}</CardInfo.InfoText>
                ) : null}
              </CardInfo.Info>
            ))}

            <CardInfo.Action onClick={() => handleChangeStep("personal")}>
              <Text size="xs" color="primary-500">
                Editar
              </Text>
              <NotePencil size={12} />
            </CardInfo.Action>
          </CardInfo.Root>
        ) : null}

        {questions.map((item) => (
          <CardInfo.Root key={item.id}>
            <CardInfo.Info>
              <CardInfo.InfoTitle>{item.title}</CardInfo.InfoTitle>
              <CardInfo.InfoText>{item.response}</CardInfo.InfoText>
            </CardInfo.Info>

            <CardInfo.Action onClick={() => handleEditQuestion(item.id)}>
              <Text size="xs" color="primary-500">
                Editar
              </Text>
              <NotePencil size={12} />
            </CardInfo.Action>
          </CardInfo.Root>
        ))}
      </div>

      <footer className="fixed inset-x-0 bottom-0 w-full bg-white px-4 py-8 shadow-[0_-1px_16px_0_rgba(0,0,0,0.15)]">
        <Button
          type="submit"
          form="form-personal"
          full
          size="lg"
          className="mx-auto max-w-3xl"
          loading={isLoading}
          disabled={isLoading}
          onClick={handleEnterToQueue}
        >
          {t("general.Confirmar")}
        </Button>
      </footer>

      {local && schedule && hour && resource ? (
        <SuccessCreateScheduling
          showModal={showModalSuccess.isOpen}
          onClose={handleCloseModal}
          id={showModalSuccess.id}
          local={local}
          schedule={schedule}
          hour={hour}
          textHelp={resource.textFinalization}
          cpf={personal?.cpf}
          onFinishedModal={clearSchedulingData}
        />
      ) : null}
    </div>
  );
}
