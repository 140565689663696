import { useTranslation } from "react-i18next";
import { Text } from "@astrolabe-ui/react";

import { ReactPortal } from "@/components/ReactPortal";
import { Modal, Button } from "@/components";

interface LinkExclusionModal {
  isOpen: boolean;
  onCloseModal: () => void;
  onConfirmation: () => void;
  loading: boolean;
}

export default function LinkExclusionModal({ isOpen, onCloseModal, onConfirmation, loading }: LinkExclusionModal) {
  const { t } = useTranslation();

  return (
    <ReactPortal>
      <Modal showModal={isOpen} onClose={onCloseModal}>
        <div className="flex flex-col items-center gap-6">
          <Text size="md" color="slate-700">
            {t("holder.Deseja desfazer o vínculo com o titular?")}
          </Text>

          <div className="w-full space-y-3">
            <Button variant="secondary" size="lg" rounded="full" full onClick={onCloseModal}>
              {t("general.Cancelar")}
            </Button>

            <Button variant="primary" size="lg" rounded="full" full onClick={onConfirmation} disabled={loading} loading={loading}>
              {t("holder.Desfazer vínculo")}
            </Button>
          </div>
        </div>
      </Modal>
    </ReactPortal>
  );
}
