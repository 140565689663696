import { http } from "@/lib/axios";
import { NewsPost } from "./types";
import { clearContentHTML } from "@/utils/clearContentHTML";
import { adjustmentsIframe } from "@/utils/adjustmentsIframe";

interface getNewsHttpResponse {
  id: number;
  titulo: string;
  conteudo: string;
  imagem: string;
  imagens_salvas: Array<{
    imagem: string;
  }>;
  multi_categorias: Array<{
    nome: string;
    id: number;
  }>;
  categoria: {
    nome: string;
    id: number;
  };
}

interface getNewsParams {
  appId: number;
  newsId: number;
}

export async function getNewsByid({ appId, newsId }: getNewsParams): Promise<NewsPost> {
  const response = await http.get<getNewsHttpResponse>(`v3/${appId}/noticias/${newsId}`);

  const data = response.data;

  const images: Array<string> = data.imagens_salvas.map((item) => item.imagem);

  return {
    id: data.id,
    title: data.titulo,
    category: {
      title: data.categoria.nome,
      categoryId: data.categoria.id,
    },
    categories: data.multi_categorias.map((item) => ({ categoryId: item.id, title: item.nome })),
    content: adjustmentsIframe(clearContentHTML(data.conteudo)),
    like: false,
    images,
    cover: data.imagem,
  };
}
