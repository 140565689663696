import { useQuery } from "@tanstack/react-query";
import { getNewMessages } from "@/services/protocols";

type UseNewMessagesParams = {
  appId: number;
  enabled: boolean;
};

export function useNewMessages({ appId, enabled }: UseNewMessagesParams) {
  return useQuery({
    queryKey: ["new_messages_protocols"],
    queryFn: () => getNewMessages({ appId }),
    enabled,
  });
}
