import { http } from "@/lib/axios";

export async function unlinkHolder({
  appId,
  holderId,
}: {
  appId?: number;
  holderId: number | null;
}) {
  try {
    const response = await http.post(`/v3/${appId}/titular/${holderId}/desvincular_titular`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}
