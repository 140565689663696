import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import { App } from "@capacitor/app";
import { CapacitorUpdater } from "@capgo/capacitor-updater";
import { Capacitor } from "@capacitor/core";
import { useQuery } from "@tanstack/react-query";
import { menuController } from "@ionic/core/components";
import { IonIcon, IonContent, IonHeader, IonText, IonToolbar } from "@ionic/react";
import {
  person,
  logOutOutline,
  calendar,
  storefrontOutline,
  personAdd,
  wallet,
  document,
  personCircleOutline,
} from "ionicons/icons";

import { SideMenuButton } from "./button";

import { isLogged, logout } from "@/services/old/auth";
import { getAppSlug } from "@/services/old/starter";
import { getSellerIdLoged } from "@/services/old/feirinha";
import { getContent } from "@/content/index";
import { STORAGE } from "@/data/storage";
import { useConfig } from "@/hooks/useConfig";
import { useUserStore } from "@/store/useUserStore";
import { useAppStore } from "@/store/useAppStore";
import { useAuthStore } from "@/store/useAuthStore";
import { queryClient } from "@/lib/react-query";

import CidadesImg from "@/assets/images/Cidades.png";
import { useTranslation } from "react-i18next";

export const PageTitle = styled.h1`
  text-align: center;
  width: 100%;
  font-size: 1.4em;
  padding: 18px 10px 12px 10px;
  margin: 0;

  @media (max-width: 700px) {
    font-size: 1.2em;
  }
`;

const Image = styled.img`
  width: 70px;
  height: 70px;
  border-radius: 40px;
  margin-top: 9px;
  margin-left: 10px;
`;

const ButtonDiv = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
`;

const UserDiv = styled.div`
  height: 89px;
  width: 100%;
  display: flex;
  flex-direction: row;
  color: #000000 !important;
`;

const UserTextDiv = styled.div`
  width: 70%;
  justify-content: center;
  height: 89px;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  color: #000000 !important;
`;

const Container = styled(IonContent)`
  --background: #ffffff !important;
  height: 100%;
`;

const UserIcon = styled(IonIcon)`
  width: 50px;
  height: 50px;
  color: white;
  margin-top: 19px;
  margin-left: 10px;
`;

const UserName = styled(IonText)`
  color: white;
  width: 100%;
  font-weight: bold;
  font-size: 18px;
  line-height: 19px;
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const UserEmail = styled(IonText)`
  color: white;
  width: 100%;
  font-size: 14px;
  line-height: 19px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const FooterImg = styled.img`
  width: 40%;
`;

const pagesProtcolsByType: Record<number, string> = {
  1: "my-services",
  2: "my-protocols",
  3: "protocols",
};

interface paramsPage {
  SLUG: string;
}

export function SideMenuContent() {
  const history = useHistory();
  const CONTENT = getContent();
  const params: paramsPage = useParams();

  const enabledOTA = useConfig({
    slug: params.SLUG,
    label: "enabled.ota",
  });

  const user = useUserStore((state) => state.user);
  const app = useAppStore((state) => state.app);
  const updateToken = useAuthStore((state) => state.updateToken);

  const { t } = useTranslation();

  const { data: appVersion } = useQuery({
    queryKey: ["appVersion"],
    queryFn: getAppVersion,
  });

  function handleLogout() {
    queryClient.clear();

    updateToken(null);

    logout();

    history.push("/" + getAppSlug() + "/auth" + "/login");
  }

  async function getAppVersion() {
    if (Capacitor.isNativePlatform()) {
      if (!enabledOTA) {
        const app = await App.getInfo();
        return app.version;
      }

      const currentApp = await CapacitorUpdater.current();
      return currentApp.bundle.version;
    }

    if (app?.version) {
      return app.version.version;
    }
  }

  const exibirVinculoTitular = useConfig({
    label: "habilitar.titularidade",
    slug: params.SLUG,
  });

  const exibirCarteira = useConfig({
    label: "habilitar.carteira",
    slug: params.SLUG,
  });

  const typeProtocols = useConfig({
    label: "page.protocols",
    slug: params.SLUG,
  });

  const urlProtocols = pagesProtcolsByType[Number(typeProtocols)];

  let holder = "";
  if (localStorage.getItem(STORAGE.AUTH_USER_HOLDER.KEY)) {
    holder = JSON.parse(localStorage.getItem(STORAGE.AUTH_USER_HOLDER.KEY)!);
  }

  if (!isLogged()) {
    return (
      <Container>
        <IonHeader>
          <IonToolbar color="primary">
            <UserDiv></UserDiv>
          </IonToolbar>
        </IonHeader>
        <PageTitle>{CONTENT.GLOBAL.NEEDTOBELOGED}</PageTitle>
      </Container>
    );
  }

  return (
    <Container>
      <IonHeader>
        <IonToolbar color="primary">
          <UserDiv>
            {user?.imageUrl ? <Image src={user.imageUrl} alt="" /> : <UserIcon icon={person} />}
            <UserTextDiv>
              <UserName>{user?.name}</UserName>
              <UserEmail>{user?.email}</UserEmail>
            </UserTextDiv>
          </UserDiv>
        </IonToolbar>
      </IonHeader>

      <ButtonDiv>
        <SideMenuButton
          routerLink={"/" + getAppSlug() + "/my-profile"}
          icon={person}
          title={CONTENT.USER.MY_PROFILE}
          onClick={() => menuController.close("main")}
        />

        {exibirVinculoTitular && exibirVinculoTitular === true && holder.length === 0 && (
          <SideMenuButton
            routerLink={`/${getAppSlug()}/link`}
            icon={personAdd}
            title={CONTENT.VINCULAR_TITULAR.TITLE_MENU}
            onClick={() => menuController.close("main")}
          />
        )}

        {exibirCarteira && exibirCarteira === true && holder.length > 0 && (
          <SideMenuButton
            routerLink={`/${getAppSlug()}/carteiras`}
            icon={wallet}
            title={CONTENT.CARTEIRA.TITLE_MENU}
            onClick={() => menuController.close("main")}
          />
        )}

        {getSellerIdLoged() > 0 && (
          <SideMenuButton
            routerLink={"/" + getAppSlug() + "/abrir-vendedor/" + getSellerIdLoged()}
            icon={storefrontOutline}
            title={CONTENT.FEIRINHA.SELLER}
            onClick={() => menuController.close("main")}
          />
        )}

        <SideMenuButton
          routerLink={"/" + getAppSlug() + "/my-events"}
          icon={calendar}
          title={CONTENT.EVENTS.MY_EVENTS_HEADER_TITLE}
          onClick={() => menuController.close("main")}
        />

        <SideMenuButton
          routerLink={`/${getAppSlug()}/${urlProtocols}`}
          icon={document}
          title={CONTENT.SERVICES.MY_PROTOCOLS_HEADER_TITLE}
          onClick={() => menuController.close("main")}
        />

        {user?.holders?.length && (
          <SideMenuButton
            routerLink={"/" + getAppSlug() + "/holder"}
            icon={personCircleOutline}
            title={t("holder.Titularidade")}
            onClick={() => menuController.close("main")}
          />
        )}

        <SideMenuButton
          icon={logOutOutline}
          title={CONTENT.GLOBAL.LOGOUT}
          onClick={() => {
            menuController.close("main");
            handleLogout();
          }}
        />
      </ButtonDiv>

      <footer className="absolute bottom-8 flex w-full flex-col items-center gap-4">
        <FooterImg src={CidadesImg} alt="" />
        {appVersion ? (
          <span className="text-sm font-medium text-slate-400">Versão {appVersion}</span>
        ) : null}
      </footer>
    </Container>
  );
}
