import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IonRippleEffect } from "@ionic/react";
import { Text } from "@astrolabe-ui/react";
import { Image, PaperPlaneRight } from "@phosphor-icons/react";
import axios from "axios";

import { Button, Loading } from "@/components";
import { decodeFormatDate } from "@/utils/decodeFormatDate";
import { ProtocolDetail, formatData } from "@/services/protocols";
import { useAppStore } from "@/store/useAppStore";
import { useSocket } from "@/hooks/useSocket";
import { http } from "@/lib/axios";

import { Support } from "./Support";
import { Client } from "./Client";
import { AvaliationResearchModal } from "../Components/AvaliationResearchModal";
import { useTabContext } from "..";
import { useMessages } from "../hooks/useMessage";
import { useConfirmMessages } from "../hooks/useConfirmMessages";

import errorIllustration from "@/assets/illustrations/error.svg";

type KeyPressed = { Shift: boolean; Enter: boolean };
const keysPressed: KeyPressed = { Shift: false, Enter: false };

interface ChatContextData {
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  dummy?: React.RefObject<HTMLDivElement>;
}

const ChatContext = createContext<ChatContextData>({} as ChatContextData);

export const useChatContext = () => useContext(ChatContext);

interface ChatProps {
  protocol: ProtocolDetail;
  dummyRef: React.RefObject<HTMLDivElement>;
}

export const Chat = ({ protocol, dummyRef }: ChatProps) => {
  const { t } = useTranslation();

  const [appId, slug, socketChannel] = useAppStore((state) => [
    state.app?.id,
    state.app?.slug,
    state.app?.socketChannel,
  ]);

  if (!appId || !slug) {
    throw new Error("Not loaded app");
  }

  const queryClient = useQueryClient();

  const { isTop } = useTabContext();

  const [messages, setMessages] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [clearBox, setClearBox] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const divFileRef = useRef<HTMLInputElement>(null);
  const boxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      protocol.categoryService.enableAvaliation &&
      protocol.status.title === "Encerrado" &&
      !protocol.avaliation
    ) {
      openModal();
    }
  }, [protocol.avaliation, protocol.categoryService.enableAvaliation, protocol.status.title]);

  function openModal() {
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false);
  }

  const sendMessage = async () => {
    setClearBox((prevState) => !prevState);

    if (boxRef.current?.innerHTML !== "") {
      const data = {
        mensagem: boxRef.current?.innerHTML,
      };

      setMessages((prevState) => prevState + 1);

      try {
        await http.post(`v3/${appId}/protocolos/${protocol.id}/mensagens`, data);
      } catch (error) {
        setMessages((prevState) => prevState - 1);
      } finally {
        dummyRef.current?.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const sendFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const data = new FormData();

    e.target.files && data.append("arquivo", e.target.files[0]);

    setMessages((prevState) => prevState + 1);

    try {
      await http.post(`v3/${appId}/protocolos/${protocol.id}/mensagens/arquivo`, data);
    } catch (error) {
      setMessages((prevState) => prevState - 1);
      if (axios.isAxiosError(error)) {
        if (error.response?.data.code === 422) {
          toast.error("O arquivo excede o tamanho máximo permitido! (9 mb)");
        } else if (error.response?.data.code === 400) {
          toast.error("Arquivo com extensão não permitida, tente outro formato!");
        } else {
          toast.error("Erro ao enviar o arquivo. Verifique os dados e tente novamente!");
        }
      }
    } finally {
      e.target.value = "";
      dummyRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const {
    data: messagesSortedByDate,
    isLoading,
    isError,
  } = useMessages({ protocolId: protocol.id, appId });

  const { mutateAsync } = useConfirmMessages();

  useEffect(() => {
    if (protocol.authorId) {
      mutateAsync(
        {
          appId,
          protocolId: protocol.id,
        },
        {
          onSuccess: () => queryClient.invalidateQueries(["new_messages_protocols"]),
        },
      );
    }
  }, [mutateAsync, appId, queryClient, protocol]);

  const { mutate } = useMutation({
    mutationFn: async (message: any) => {
      const messagesSortedByDateCopy = { ...messagesSortedByDate };

      if (!messagesSortedByDateCopy[message.created_at.split("T")[0]])
        messagesSortedByDateCopy[message.created_at.split("T")[0]] = [formatData(message)];
      else messagesSortedByDateCopy[message.created_at.split("T")[0]].push(formatData(message));

      queryClient.setQueryData(["protocol", "message", protocol.id], messagesSortedByDateCopy);
    },
  });

  const watchMessage = (data: any) => {
    mutate(data, {
      onSuccess: () => console.log("on success to received message"),
      onError: () => toast.error("Houve um erro ao receber a mensagem"),
    });

    if (data?.interno) {
      mutateAsync({
        appId,
        protocolId: protocol.id,
      });

      if (data.mensagem?.includes("Status do protocolo alterado para:")) {
        queryClient.refetchQueries({ queryKey: ["protocols", protocol.id] });
      }
    }

    dummyRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const onKeyPressed = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key in keysPressed) keysPressed[e.key as keyof KeyPressed] = true;
    if (!keysPressed.Shift && e.key === "Enter" && boxRef.current?.textContent) {
      setLoading(true);
      sendMessage();
    }
  };

  const onKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key in keysPressed) keysPressed[e.key as keyof KeyPressed] = false;
  };

  useSocket({ channel: `${socketChannel}.protocolo.${protocol.id}`, watchMessage });

  const qtdMessages = messagesSortedByDate && Object.values(messagesSortedByDate).flat().length;

  useEffect(() => {
    if (qtdMessages && qtdMessages > 0) {
      setLoading(messages > qtdMessages);
      messages === 0 && setMessages(qtdMessages);
    }

    !isTop && dummyRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [dummyRef, isTop, messages, qtdMessages]);

  return (
    <div className="flex h-full flex-col">
      <ChatContext.Provider value={{ loading, setLoading, dummy: dummyRef }}>
        <div className="flex h-full flex-col gap-2 px-4 pt-4">
          {isLoading ? (
            <div className="flex h-full justify-center">
              <Loading variant="secondary" />
            </div>
          ) : isError ? (
            <div className="w-ful flex h-full flex-col items-center justify-center gap-6 px-4 text-center">
              <img src={errorIllustration} alt="error illustration" />
              <div className="flex flex-col gap-2">
                <Text weight="medium" color="slate-700">
                  {t("error.Erro ao carregar o chat")}
                </Text>
              </div>
            </div>
          ) : (
            Object.keys(messagesSortedByDate).map((date) => (
              <React.Fragment key={date}>
                <div className="flex justify-center">
                  <Text size="xs">{decodeFormatDate(date, "long", true)}</Text>
                </div>
                {messagesSortedByDate[date]?.map((message) =>
                  message.support ? (
                    <Support key={message.id} message={message} />
                  ) : (
                    <Client key={message.id} message={message} />
                  ),
                )}
              </React.Fragment>
            ))
          )}
          {loading && (
            <div className="flex justify-end">
              <Loading variant="secondary" />
            </div>
          )}
          <div ref={dummyRef} className="h-4" />
        </div>

        {protocol.authorId && protocol.status.title !== "Encerrado" && (
          <div className="sticky bottom-0 z-20 flex h-full items-end bg-white px-4 pb-3 pt-2">
            <div className="flex w-full items-center gap-2">
              <button
                className="ion-activatable ripple-parent flex h-10 w-10 flex-shrink-0 items-center justify-center self-end rounded-full"
                onClick={() => divFileRef.current?.click()}
              >
                <Image alt="image icon" size={20} weight="fill" className="text-primary-500" />

                <input
                  type="file"
                  hidden
                  ref={divFileRef}
                  onChange={(e) => {
                    setLoading(true);
                    sendFile(e);
                  }}
                />

                <IonRippleEffect className="text-slate-300" />
              </button>

              <div className="flex grow justify-center gap-2 overflow-hidden rounded-lg bg-slate-200 px-3 py-2">
                <div
                  contentEditable
                  key={String(clearBox)}
                  onKeyDown={(e) => onKeyPressed(e)}
                  onKeyUp={(e) => onKeyUp(e)}
                  ref={boxRef}
                  className="hide-scrollbar max-h-28 grow self-center overflow-auto font-sans text-xs text-slate-700 focus:outline-none [&_strong]:font-semibold"
                />

                <button
                  className="ion-activatable ripple-parent flex flex-shrink-0 items-center justify-center self-end p-[2px]"
                  onClick={sendMessage}
                >
                  <PaperPlaneRight
                    alt="send icon"
                    size={20}
                    weight="fill"
                    className="text-primary-500"
                  />

                  <IonRippleEffect className="text-slate-100" />
                </button>
              </div>
            </div>
          </div>
        )}

        {protocol.authorId &&
        protocol.categoryService.enableAvaliation &&
        protocol.status.title === "Encerrado" &&
        !protocol.avaliation ? (
          <div className="mx-4 mb-6">
            <Button full size="lg" onClick={openModal}>
              {t("avaliationResearch.Responder pesquisa de satisfação")}
            </Button>
          </div>
        ) : null}
      </ChatContext.Provider>

      <AvaliationResearchModal
        isOpened={isModalOpen}
        onCloseModal={closeModal}
        enableService={!!protocol.categoryService.enableAvaliationService}
      />
    </div>
  );
};
