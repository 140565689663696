import { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { IonRippleEffect, useIonRouter } from "@ionic/react";
import { CaretLeft, LinkBreak } from "@phosphor-icons/react";
import { Box, Text } from "@astrolabe-ui/react";

import { formatCpf } from "@/utils/formats";

import { Layout } from "@/templates/Layout";
import { Content } from "@/templates/Content";
import { Header } from "@/components/Header";
import LinkExclusionModal from "../components/link-exclusion-modal";
import { Button, HeaderButton } from "@/components/index";
import { useAppId, useAppSlug } from "@/store/useAppStore";
import { useHolders } from "@/store/useUserStore";
import { useUnlinkHolders } from "./hooks/use-unlink-holders";
import { updateUser } from "@/services/users";

export function HoldersList() {
  const history = useIonRouter();
  const { t } = useTranslation();
  const appId = useAppId()
  const slug = useAppSlug();
  const holders = useHolders();

  const { mutateAsync, isLoading, isError } = useUnlinkHolders()

  const [isOpen, setIsOpen] = useState(false);
  const [selectedHolderId, setSelectedHolderId] = useState<number | null>(null);

  function handleGoBack() {
    history.push(`/${slug}/home`);
  }

  function newLink() {
    history.push(`/${slug}/link`);
  }

  function handleCloseModal() {
    setIsOpen(false);
  }

  function openModal(e: React.MouseEvent<HTMLDivElement, MouseEvent>, holderId: number) {
    e.preventDefault();
    e.stopPropagation();
    setSelectedHolderId(holderId);
    setIsOpen(true);
  }

  async function handleConfirmation() {
    await mutateAsync(
      { appId, holderId: selectedHolderId },
      {
        onSuccess: () => {
          toast.success("Titular desvinculado com sucesso!");
          setIsOpen(false)
          updateUser()
        },
        onError: () => {
          toast.error("Erro ao desvincular titular!")
        }
      }
    )
  }

  return (
    <Layout>
      <Header>
        <div className="flex w-full items-center after:flex-1 after:content-['']">
          <div className="flex-1">
            <HeaderButton icon={<CaretLeft weight="bold" />} onClick={handleGoBack} />
          </div>
          <Text size="lg" weight="medium" leading="relaxed" className="text-textHeader">
            {t("holder.Titulares")}
          </Text>
        </div>
      </Header>

      <Content>
        <div className="mx-auto flex min-h-full w-full max-w-2xl flex-col gap-4 px-4 py-6 ios:pb-2 flex-grow">
          {isError ? (
            <div className="w-ful flex h-full flex-col items-center justify-center gap-6 px-4 text-center">
              <div className="flex flex-col gap-2">
                <Text weight="medium" color="slate-700">
                  {t("holder.Desculpe, algo deu errado")}...
                </Text>
                <Text>{t("holder.Tente novamente mais tarde")}</Text>
              </div>
            </div>
          ) : null}

          {holders?.length === 0 && !isError ? (
            <div className="flex mt-6 flex-col gap-1 items-center">
              <Text weight="medium" size="md" color="slate-700" className="text-center">
                {t("holder.Sem titulares vinculados.")}
              </Text>
              <Text size="sm" weight="medium" className="text-center">
                {t("holder.Clique no botão abaixo para adicionar um novo vínculo!")}
              </Text>
            </div>
          ) : null}

          {holders && holders.length > 0 ? (
            <div className="flex flex-col gap-3">
              <Text size="md" weight="medium" color="slate-700" className="line-clamp-1">
                {t("holder.Selecione o titular abaixo")}
              </Text>

              {holders?.map((holder) => (
                <Link to={`/${slug}/titulares/${holder.id}`} key={holder.id}>
                  <Box
                    px={3}
                    py={3}
                    className="flex items-center gap-3 border-thin hover:cursor-pointer ion-activatable ripple-parent">
                    <div className="flex justify-between items-center w-full">
                      <div className="flex flex-col gap-0.5 overflow-hidden">
                        <Text size="md" weight="medium" color="slate-700" className="line-clamp-1">
                          {holder.name}
                        </Text>
                        <Text size="xs" weight="medium" color="slate-400" className="line-clamp-2">
                          {formatCpf(holder.cpf)}
                        </Text>
                      </div>
                      <div className="border-thin rounded-md border-slate-300" onClick={(e) => openModal(e, holder.id)}>
                        <LinkBreak className="text-red-400 m-1" size={18} />
                      </div>
                    </div>

                    <IonRippleEffect className="text-slate-400" />
                  </Box>
                </Link>
              ))}
            </div>
          ) : null}

          <Button size="lg" className="mt-auto" onClick={newLink}>
            {t("holder.Adicionar novo vínculo")}
          </Button>
        </div>
        <LinkExclusionModal
          loading={isLoading}
          isOpen={isOpen}
          onCloseModal={handleCloseModal}
          onConfirmation={handleConfirmation}
        />
      </Content>
    </Layout>
  );
}
