import { z } from "zod";
import { useAtom } from "jotai";
import i18n from "@/lib/i18next";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { Layout } from "@/templates/Layout";
import { Header } from "@/components/Header";
import { Content } from "@/templates/Content";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CaretLeft } from "@phosphor-icons/react";
import { HeaderButton } from "@/components/index";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAlterEmail } from "../hooks/useAlterEmail";
import { useAppId, useAppSlug } from "@/store/useAppStore";
import { alterEmailAtom } from "../atoms/AtomAlterEmail";
import { Button, Text, TextInput } from "@astrolabe-ui/react";

const EmailConfirmationFormSchema = z
  .object({
    email: z
      .string()
      .min(1, i18n.t("auth.Informe seu e-mail"))
      .email(i18n.t("auth.Informe um e-mail válido")),
    novoEmail: z
      .string()
      .min(1, i18n.t("auth.Informe seu e-mail"))
      .email(i18n.t("auth.Informe um e-mail válido")),
  })
  .refine((data) => data.email === data.novoEmail, {
    path: ["novoEmail"],
    message: i18n.t("auth.Os emails devem serem iguais"),
  });

type EmailConfirmationFormData = z.infer<typeof EmailConfirmationFormSchema>;

export function AlterEmail() {
  const { t } = useTranslation();
  const history = useHistory();
  const appId = useAppId();

  const { mutateAsync } = useAlterEmail();
  const slug = useAppSlug();

  const [alterEmail, setAlterEmail] = useAtom(alterEmailAtom);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<EmailConfirmationFormData>({
    resolver: zodResolver(EmailConfirmationFormSchema),
    mode: "onChange",
    defaultValues: {
      email: alterEmail?.email ?? "",
      novoEmail: "",
    },
  });

  async function onSubmit(data: EmailConfirmationFormData) {
    const { novoEmail } = data;

    await mutateAsync(
      {
        email: alterEmail?.email,
        novoEmail,
        appId,
        ...(alterEmail?.password ? { password: alterEmail?.password } : {}),
      },
      {
        onSuccess: () => {
          history.replace(`/${slug}/auth/login`);
          toast.success("E-mail alterado com sucesso");
          setAlterEmail({ email: "", password: "" });
        },
        onError: (err) => {
          console.error(err);
          toast.error("Erro ao tentar alterar o e-mail");
        },
      },
    );
  }

  const handleBack = () => {
    history.goBack();
  };

  return (
    <Layout>
      <Header variant="secondary">
        <div className="flex w-full items-center after:flex-1 after:content-['']">
          <div className="flex-1">
            <HeaderButton
              variant="secondary"
              icon={<CaretLeft weight="bold" />}
              onClick={handleBack}
            />
          </div>

          <Text size="md" weight="medium" leading="relaxed" color="primary-500">
            {t("auth.Alteração de e-mail")}
          </Text>
        </div>
      </Header>

      <Content>
        <div className="flex min-h-full w-full flex-col items-center gap-8 px-4 py-6 ios:pb-2">
          <div className="flex flex-col text-center">
            <Text className="text-left" asChild size="md" weight="medium" color="slate-700">
              <strong>{t("auth.Informe um email válido para realizar a alteração")}</strong>
            </Text>
          </div>
          <form
            id="alterEmailForm"
            onSubmit={handleSubmit(onSubmit)}
            className="flex h-full w-full flex-col gap-24"
          >
            <div className="flex flex-col gap-4">
              <TextInput
                floating
                type="email"
                placeholder={t("auth.Insira seu e-mail")}
                autoCapitalize="none"
                error={errors.email?.message}
                {...register("email")}
              />
              <TextInput
                floating
                type="email"
                placeholder={t("auth.Confirme seu e-mail")}
                autoCapitalize="none"
                error={errors.novoEmail?.message}
                {...register("novoEmail")}
              />
            </div>

            <div className="mt-8 flex flex-col gap-y-3 text-left">
              <Text size="md" color="slate-700" weight="medium">
                {t(
                  "auth.Um e-mail será enviado para confirmar a sua identidade, contendo um botão de confirmação.",
                )}
              </Text>

              <Text size="sm" weight="normal">
                {t(
                  "auth.Este processo é uma camada extra de  segurança, essencial para assegurar a validade e proteção dos seus dados.",
                )}
              </Text>
            </div>
          </form>
          <footer className="mt-auto flex w-full flex-col">
            <Button
              variant="primary"
              size="lg"
              rounded="md"
              full
              className="font-medium"
              form="alterEmailForm"
              type="submit"
            >
              {t("auth.Enviar confirmação para o meu e-mail")}
            </Button>
          </footer>
        </div>
      </Content>
    </Layout>
  );
}
