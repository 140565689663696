const es = {
  GLOBAL: {
    SEARCH: "Buscar",
    MAINTENANCE: "En mantenimiento",
    LOADING: "Cargando...",
    LOAD_MORE: "carga más",
    DONE: "Listo",
    CONTINUE: "Continuar",
    SHARE: "Cuota",
    SHARE_MSG: "El enlace de acceso se ha copiado en su portapapeles.",
    NEEDTOBELOGED: "Necesitas crear o iniciar sesión en su cuenta para ver este contenido.",
    LOGOUT: "Salir",
    ACCEPT: "Aceito",
    PAGE_NOT_FOUND: "¡Página no encontrada!",
    TOUCH_TO_OPEN: "Toque para abrir",
    NOCONTENT: "No hay artículos registrados",
    OPEN: "Abrir",
    CLOSE: "Cerca",
    NEXT: "Siguiente",
    PREV: "Atrás",
    SELECT: "Seleccionar",
    FILES: "Archivos",
    SELECT_FILE: "Seleccionar archivo",
    CHANGE_FILE: "Cambiar archivo",
    DOWNLOAD_LAST_SENT: "Descargar último enviado",
    MONTHS: ["Ene", "Feb", "Mar", "Abr", "Mayo", "Jun", "Jul", "Ago", "Sep", "Fuera", "Nov", "Dic"],
  },
  STORE_EVALUATION: {
    TITLE: "¿Qué opinas de la aplicación?",
    RATE: "Evaluar",
    COMMENT_ON_STORE: "Enviar comentario de la tienda",
    SEND: "Enviar",
    CANCEL: "Cancelar",
    DONT_SHOW_AGAIN: "No mostrar de nuevo",
  },
  FORM: {
    HEADER: "Investigación",
    ANSWER: "Responder",
    ANSWERED: "Respondido",
    ANSWERED_MSG: "Ya ha respondido este cuestionario",
    FULL_ANSWERED_MSG: "Ya se han llenado todas las vacantes",
    BT_RESERVE_ANSWER: "Responder vacante",
    ALERT_NOT_ENOGHT_ANSWERS: "No ha respondido el número mínimo de preguntas necesarias",
    ALERT_TO_MUCH_ANSWERS: "Las opciones máximas de respuesta son ",
    ALERT_MISSING_ANSWERS: "No ha respondido a todas las preguntas seleccionadas",
    MIN_SELECT_AMOUNT_P1: "Responde al menos a ",
    MIN_SELECT_AMOUNT_P2: " preguntas.",
  },
  USER: {
    MY_PROFILE: "Mi perfil",
    PROFILE_IMAGE: "Foto de perfil",
    PROFILE_UPDATE: "Actualización del perfil",
    CHANGE_PASSWORD: "Cambiar contraseña",
    CHANGE_PASSWORD_MSG:
      "Se ha enviado un código de confirmación a su correo electrónico. Por favor, para cambiar su contraseña, ingrese el código de confirmación en el espacio a continuación.",
    WELCOME_MSG: "Bienvenido a",
    EMAIL: "Correo electrónico",
    EMAILCPF: "Correo electrónico/CPF",
    CPF: "CPF",
    PASSWORD: "Contraseña",
    PASSWORD_REPEAT: "Confirmar seña",
    FORGOTTEN_PASSWORD: "Olvidé mi contraseña",
    LOGIN: "Iniciar sesión",
    REGISTER: "Registrarse",
    NAME: "Nombre",
    ALERT_USER_UPDATE_TITLE: "¡Listo!",
    ALERT_USER_UPDATE_MSG: "Tu perfil se ha actualizado correctamente",
    ALERT_USER_UPDATE_BT: "Siguiente",
    USER_DATA: "Datos del usuario",
    CHANGE_PASSWORD_TOKEN: "Código",
    CHANGE_PASSWORD_TOKEN_PLACEHOLDER: "Código de confirmación",
    CHANGE_PASSWORD_TOKEN_VALIDATION: "Validar código",
    CHANGE_PASSWORD_TOKEN_VALIDATION_TITLE: "Validar código",
    CHANGE_PASSWORD_TOKEN_VALIDATION_MSG:
      "Se ha enviado un código a su correo electrónico. Por favor, para recuperar su contraseña, ingrese el código de confirmación a continuación.",
    CHANGE_PASSWORD_ERROR_NEED_EMAIL: "Correo electronico es requerido",
    CHANGE_PASSWORD_ERROR_NEED_CPF: "CPF es obligatorio",
    CHANGE_PASSWORD_ERROR_NEED_NAME: "El nombre es obligatorio",
    CHANGE_PASSWORD_ERROR_NEED_TOKEN: "El código de confirmación es obligatorio",
    CHANGE_PASSWORD_ERROR_NEED_PASSWORD: "Se requiere la contraseña.",
    CHANGE_PASSWORD_ERROR_NEED_CURRENT_PASSWORD: "Debe ingresar la contraseña actual.",
    CHANGE_PASSWORD_ERROR_DIFERENTS_PASSWORD: "Las contraseñas no coinciden",
    CHANGE_PASSWORD_ERROR_EQUALS_TO_CURRENT: "La nueva contraseña es la misma que la actual.",
    CHANGE_PASSWORD_CURRENT: "Contraseña actual",
    CHANGE_PASSWORD_NEW: "Nueva contraseña",
    CHANGE_PASSWORD_REPEAT_NEW: "Confirmar nueva contraseña",
    ALERT_PASSWORD_UPDATE_TITLE: "¡Listo!",
    ALERT_PASSWORD_UPDATE_MSG: "Su contraseña se ha restablecido correctamente.",
    ALERT_PASSWORD_UPDATE_REQUEST_MSG: "El código de validación se ha enviado a",
    ALERT_PASSWORD_UPDATE_BT: "Avance",
    REQUEST_CHANGE_PASSWORD_EMAIL_MSG1:
      "Por favor ingrese el correo electrónico de la cuenta para la que desea restablecer la contraseña. Se enviará un código de confirmación a su correo electrónico.",
    REQUEST_CHANGE_PASSWORD_EMAIL_MSG2:
      "Si ya tiene un código de confirmación, use la opción 'Ya tengo un código de confirmación'.",
    REQUEST_CHANGE_PASSWORD_I_HAVE_TOKEN: "Ya tengo un código de confirmación",
    REQUEST_CANCEL_ACCOUNT: "Eliminar Cuenta",
    ALERT_START_CANCEL_ACCOUNT_PROCESS:
      "¡Al confirmar la eliminación de la cuenta, pierde el acceso a la cuenta, las inscripciones realizadas y el acceso a los protocolos abiertos! ¡Recuerda que este proceso es irreversible! Si desea continuar, haga clic en confirmar a continuación.",
    ALERT_START_CANCEL_ACCOUNT_PROCESS_TITLE: "¡Borrar cuenta!",
    STOP_CANCEL_ACCOUNT_PROCESS:
      "¡Caso queira cancelar o processo de exclusão clique no botão confirmar abaixo!",
    STOP_CANCEL_ACCOUNT_PROCESS_TITLE: "¡Cancelar el proceso de eliminación!",
    CREDENTIALS_DONT_MATCH: "Credenciais não conferem!",
    VERIFY_EMAIL: "¡Se requiere verificación de correo electrónico!",
    VERIFY_EMAIL_TITLE: "Verificacion de email",
    RESEND_EMAIL:
      "¡Necesitas verificar tu correo electrónico para iniciar sesión! Si es necesario, haga clic en el botón a continuación para volver a enviar el correo electrónico de confirmación.",
    CPF_OR_EMAIL_ALREADY_REGISTERED:
      "¡El cpf o correo electrónico ya está en uso en esta aplicación!",
    CPF_OR_EMAIL_ALREADY_REGISTERED_TITLE: "Cpf o correo electrónico ya en uso!",
    GUEST: "Entrar como invitado",
  },

  USERTERMS: {
    TITLE: "Condiciones de uso",
    ACCEPT_MSG: "He leído y acepto las condiciones de uso",
    ALERT_FAIL: "¡Atención!",
    ALERT_FAIL_MSG: "Debe aceptar los términos antes de continuar",
  },

  LOCATION: {
    YOU_ARE_HERE: "Tu estás aquí",
    TITLE: "Ubicación",
    LOADING: "Cargando ubicación ...",
    ROAD: "Carretera",
    NEIGHBORHOOD: "Región",
    ERROR_NEED_NEIGHBORHOOD: "La región es obligatoria",
    POSTCODE: "ZIP",
    ESTATE: "Estado",
    ADDRESS: "Dirección",
    NUMBER: "Número",
    UNKONW_LOCATION: "Ubicación no definida",
    CLOSE_MAP: "Cerrar mapa",
    OPEN_MAP: "Abrir mapa",
    SEARCH_HERE: "Buscar aquí",
    SEARCH: "Buscar",
    CHANGE_ADDRESS_MSG: "* Haga clic en el texto de la dirección para cambiarla",
    ALERT_FAIL_TITLE: "No se pudo encontrar la dirección solicitada.",
    ALERT_FAIL_MSG:
      "Verifique la dirección ingresada o busque una calle cercana ajustando manualmente el mapa",
    ALERT_SELECT_LOCATION_TITLE: "¿Qué ubicación quieres usar?",
    DONE: "Listo",
    ALERT_FAIL_MSG_LOCATION: "Debes estar cerca del punto de programación para realizar la acción.",
  },

  IMAGE: {
    FILE_FAIL: "Archivo inválido",
  },

  // news page
  NEWS: {
    HEADER_TITLE: "Noticias",
    PAGE_TITLE: "Ultimas Noticias",
    SEE_ALL: "Todas las noticias",
  },

  // events page
  EVENTS: {
    HEADER_TITLE: "Acontecimientos",
    MY_EVENTS_HEADER_TITLE: "Mis acontecimientos",
    HEADER_TITLE_OPEN: "Acontecimiento",
    PAGE_TITLE: "Últimos acontecimientos",
    SUBSCRIBE: "Agregar a la programación",
    UNSUBSCRIBE: "Eliminar del calendario",
    RELATED: "Eventos relacionados",
  },

  // Informations
  INFORMATION: {
    HEADER_TITLE: "Información",
    HEADER_TITLE2: "Información",
    NEXT: "Proximo",
    BACK: "Anterior",
    DETAIL: "Detalhar",
    FILTER: "Filtrar por etiqueta",
  },

  // service page
  SERVICES: {
    HEADER_TITLE: "Servicios",
    PAGE_TITLE: "Mesa de servicio",
    MY_PROTOCOLS_HEADER_TITLE: "Meus protocols",
  },
  // protocol page
  PROTOCOLS: {
    PROTOCOL: "Protocolo",
    HEADER_TITLE: "Protocolos",
    PAGE_TITLE: "Mis protocolos",
    OPEN_CHAT: "Ver mensajes",
    INDENTIFIER: "Identificador",
    PLATE: "Plato",
    STATE: "Situación",
    ADDRESS: "Dirección",
    CATEGORY: "Categoría",
    CREATE_AT: "Creado el",
    MESSAGES: "Mensajes",
    OBSERVATIONS: "comentarios",
    SELECT_CATEGORY: "Seleccionar ocurrencia",
    REGISTER: "Solicitud abierta",
    IMAGE: "Solicitar imagen",
    ERROR_CREATE_TITLE: "Falla",
    ERROR_CREATE_NEED_WAIT:
      "Recientemente presentó una solicitud en esta categoría. Espere y vuelva a intentarlo.",
    ERROR_SELECT_LOCATION: "Seleccionar ubicación",
    ERROR_SELECT_CATEGORY: "Seleccionar ocurrencia",
    ERROR_SELECT_IMAGE: "Seleccionar una imagen",
    ERROR_MESSAGE: "El mensaje es obligatorio",
    ADDRESS_PLACEHOLDER: "Nombre y número de la calle",
    VALUE: "Valor",
    CANT_ACCESS:
      "No es posible consultar este protocolo, Código inválido o no tienes permiso para visualizarlo.",
    ETERNAL_SERVICE: "Acceder a la página de servicio",
    HORARIOATENDIMENTO:
      "Este serviço não encontra-se em horário de atendimento, mas você pode seguir com o cadastro.\n\nAssim que possível um atendente dará sequência a sua solicitação.",
    NAME: "Nombre",
    ERROR_NAME: "El nombre es obligatorio",
    WITHOUT_IDENTIFICATION: "Sin identificación",
    FIELD_REQUIRED: "Este campo es obligatorio.",
  },

  // loading alert dialog
  ALERT_DIALOG: {
    TITTLE: "Cargando",
    CHAT_ERROR: "No se pudo llegar al servidor.",
  },

  // chat componte
  CHAT: {
    INPUT_TEXT_HINT: "Escribelo...",
    FILE: "Expediente",
  },

  FEIRINHA: {
    HEADER_TITLE: "Feira Virtual",
    EDIT_PRODUCT: "Editar producto",
    EDIT_SELLER: "Editar perfil de vendedor",
    CATEGORY: "Categoria",
    SELECT_CATEGORY: "Selecionar categoria",
    CHANGE_CATEGORY: "Alterar categoria",
    ALL_CATEGORIES: "Todas categorias",
    PRODUCTS: "Produtos",
    DESCRIPTION: "Descrição",
    SHARE: "Compartilhar",
    PHONE: "Teléfono",
    SELLER: "Vendedor",
    SELLER_REGISTER: "Registrar vendedor",
    PRODUCT_REGISTER: "Registrar producto",
    DELETE_REGISTER: "Eliminar producto",
    DELETE_REGISTER_MSG: "¿Está seguro de que desea eliminar el producto?",
    ERROR_NEED_PHONE: "El teléfono es obligatorio",
    UNITY: "Unidad",
    VALUE: "Precio",
    SELECT_MODULE: "eleccione la feria",
    MODULE: "Feria",
    ERROR_NEED_NAME: "El nombre es obligatorio",
    ERROR_NEED_UNITY: "La unidad es obligatoria",
    ERROR_NEED_DESCRIPTI: "A descrição é obrigatória",
    ERROR_NEED_VALUE: "El valor es obligatorio",
    ERROR_NEED_MODULE: "La feria es obligatoria",
    ERROR_NEED_CATEGORY: "La categoría es obligatoria",
    SEND_TO_VISIBLE: "Tornar visível",
    SEND_TO_REVISION: "Enviar para revisão",
    SEND_TO_HIDE: "Tornar oculto",
    IN_REVISION: "Em revisão",
    HIDE_BY_SELLER: "Oculto pelo comerciante",
    FAILED_IN_REVISION: "Reprovado na revisão",
    WARNING_TITTLE: "Advertencia",
    WARNING_TEXT: "¡Producto bajo revisión, será lanzado pronto!",
  },

  CAMERA: {
    TITLE: "Cámara",
    MODULES: "Modulos",
    TRY_AGAIN: "Toma otro",
    USE_THIS: "Usar imagen",
    COMMENT: "Comentário",
    SEND: "Enviar",
    WARNING_TITTLE: "Advertencia",
    WARNING_TEXT: "¡Imagem bajo revisión, será lanzado pronto!",
    WARNING_CLOSE: "Cerrar",
    ADD_DESCRIPTION: "Añadir una descripción",
    DESCRIPTION: "Descripción",
  },
  GET_IMAGE: {
    MESSAGE: "Seleccione la fuente de la imagen",
    CAMERA: "Cámara",
    FILE: "Archivos",
  },

  VINCULAR_TITULAR: {
    TITLE: "Vinculación de los titulares",
    TITLE_MODAL: "Titular del enlace",
    TITLE_MENU: "Enlace al Titular",
    VALIDATE: "Validación de datos",
    COMPLETE: "Complete los siguientes pasos para completar el enlace de los titulares.",
    CHECKOUT: "Revisa tus datos antes de confirmar, solo tendrás 1 intento.",
    START: "Comienzo",
    RETURN: "Regresar a la página principal",
    VALIDATE_SUCCESS: "La validación del titular se realizó con éxito.",
    ORIENTATION:
      "¡Listo! Su cuenta en la aplicación se ha vinculado a un perfil de titular. Ahora tiene derecho a los beneficios de las instituciones a las que está afiliado.",
    CONTACT_MESSAGE:
      "Póngase en contacto con los organismos responsables para obtener más información.",
    STEP_CPF: "Ingrese su número de CPF",
    STEP_NAME: "Seleccione el nombre de su madre",
    STEP_DATE: "Selecciona tu fecha de nacimiento",
    STEP_CONFIRM: "Confirmar los datos",
    CPF_REQUIRED: "CPF es obligatorio",
    CPF_INVALID: "CPF no válido",
    CPF_BLOCKED: "CPF bloqueado",
    CPF_BLOCKED_MESSAGE:
      "Póngase en contacto con la institución responsable para realizar el desbloqueo.",
    CPF_NOT_FOUND: "CPF no encontrado",
    CPF_NOT_FOUND_MESSAGE:
      "El número de CPF no está vinculado a ninguna institución registrada en la aplicación.",
    TRY_AGAIN: "Intentar nuevamente",
    ADVANCE: "Ventaja",
    CONCLUDE: "Concluir",
    CPF: "CPF",
    MOTHER_NAME: "Nombre de la madre",
    BIRTH_DATA: "Fecha de nacimiento",
    CONFIRM: "Confirmar datos",
    RETURN_STEP: "Volver",
    STEP: "Paso",
    CPF_UPDATED: "Actualizar datos",
    CPF_UPDATED_MESSAGE:
      "¿Quieres incluir el CPF informado a los datos de tu perfil de aplicación? En caso afirmativo, haga clic en el botón correspondiente a continuación.",
    UPDATED: "Actualizar",
    NOT: "No gracias",
    CPF_UPDATED_ERROR: "Error al actualizar perfil",
    CPF_UPDATED_SUCCESS: "Perfil actualizado",
    CPF_IN_USE: "CPF em uso!",
    CPF_IN_USE_MESSAGE: "Já existe um usuário vinculado ao titular desse cpf!",
    INCORRECT_DATA: "Dados incorretos!",
    INCORRECT_DATA_MESSAGE: "Algum dos dados passados está incorreto",
  },

  TITULAR: {
    TITULARIDADE: "Propiedad",
  },

  CARTEIRA: {
    TITLE_MENU: "Mis billeteras",
  },
};

export default es;
