import { ReactNode, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { IonRippleEffect } from "@ionic/react";
import {
  Text,
  TextInput,
  CardRoot,
  CardIndicator,
  CardImage,
  CardContent,
  CardTitle,
} from "@astrolabe-ui/react";
import { CaretLeft, FadersHorizontal, MagnifyingGlass } from "@phosphor-icons/react";

import { Layout } from "@/templates/Layout";
import { Content } from "@/templates/Content";
import { Header, HeaderButton, IconButton, Loading } from "@/components";
import { CardServices } from "./pages/Services/components/Card";
import OrderModal from "./Modal";
import { sortAreaCategories } from "./utils/sortAreaCategories";
import { useCategoriesService } from "./hooks/useCategoryServices";
import { useSearchServices } from "./hooks/use-search-services";
import { useAppId, useAppSlug } from "@/store/useAppStore";
import useQueryParams from "@/utils/queryParamsHook";

import illustrationEmpty from "@/assets/illustrations/empty.svg";

export function CategoriesServices() {
  const { t } = useTranslation();
  const history = useHistory();
  const queryParams = useQueryParams();

  const appId = useAppId();
  const slug = useAppSlug();

  if (!appId || !slug) {
    throw new Error("Not loaded app");
  }

  const [query, setQuery] = useState<string>("");
  const [showOrderModal, setShowOrderModal] = useState(false);

  const queryClient = useQueryClient();

  const { data, isLoading } = useCategoriesService({
    appId,
    aggregatorOnly: queryParams.get("aggregator_only"),
    aggregatorExcept: queryParams.get("aggregator_except"),
  });

  const { data: servicesBySearch, isLoading: isLoadingServicesBySearch } = useSearchServices({
    appId,
    query,
    aggregatorOnly: queryParams.get("aggregator_only"),
    aggregatorExcept: queryParams.get("aggregator_except"),
  });

  function handleOrderResults(order: "asc" | "desc") {
    queryClient.setQueryData(
      [
        "categories-service",
        appId,
        queryParams.get("aggregator_only"),
        queryParams.get("aggregator_except"),
      ],
      [...sortAreaCategories(data ?? [], order)],
    );
  }

  function handleNavigate() {
    history.replace(`/${slug}`);
  }

  return (
    <Layout>
      <Header>
        <div className="flex w-full items-center after:flex-1 after:content-['']">
          <div className="flex-1">
            <HeaderButton onClick={handleNavigate} icon={<CaretLeft weight="bold" />} />
          </div>

          <Text size="md" weight="medium" leading="relaxed" className="text-textHeader">
            {t("modules.Serviços")}
          </Text>
        </div>
      </Header>

      <Content>
        <OrderModal
          updateAreasForOrder={handleOrderResults}
          setShowOrderModal={setShowOrderModal}
          showModal={showOrderModal}
        />

        <div className="mx-auto flex min-h-full w-full max-w-3xl flex-col gap-5 px-4 py-6 ios:pb-2">
          <header className="flex items-center gap-1.5">
            <TextInput
              icon={<MagnifyingGlass className="text-slate-700" />}
              placeholder={t("services.Filtrar por nome da área")}
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />

            <IconButton
              icon={<FadersHorizontal className="text-slate-700" />}
              size="lg"
              onClick={() => setShowOrderModal(true)}
              variant="tertiary"
              rounded="full"
            />
          </header>

          <div className="flex flex-1 flex-col gap-4">
            {data?.length && !query
              ? data.map((categoryService) => (
                  <Link
                    to={`/${slug}/categories-services/${categoryService.id}/services?area=${categoryService.title}`}
                    key={categoryService.id}
                  >
                    <CardWrapper>
                      <CardRoot>
                        {categoryService.image ? <CardImage src={categoryService.image} /> : null}

                        <CardContent>
                          <CardTitle>{categoryService.title}</CardTitle>
                        </CardContent>

                        <CardIndicator />
                      </CardRoot>
                    </CardWrapper>
                  </Link>
                ))
              : null}

            {servicesBySearch?.length && !!query
              ? servicesBySearch.map((service) => (
                  <CardServices key={service.id} category={service} />
                ))
              : null}

            {isLoading || (!!query && isLoadingServicesBySearch) ? (
              <div className="flex min-h-full w-full items-center justify-center px-4 py-6 ios:pb-2">
                <Loading variant="secondary" size="md" />
              </div>
            ) : null}

            {(!data?.length && !isLoading) ||
            (!servicesBySearch?.length && !isLoadingServicesBySearch) ? (
              <EmptyList />
            ) : null}
          </div>
        </div>
      </Content>
    </Layout>
  );
}

interface CardWrapperProps {
  children: ReactNode;
}

function CardWrapper({ children }: CardWrapperProps) {
  return (
    <div className="ion-activatable ripple-parent w-full">
      {children}
      <IonRippleEffect className="text-slate-200" />
    </div>
  );
}

function EmptyList() {
  return (
    <div className="w-ful flex h-full flex-col items-center justify-center gap-6 px-4 text-center">
      <img src={illustrationEmpty} alt="" />

      <div className="flex flex-col gap-2">
        <Text weight="medium" color="slate-700">
          Nenhum resultado encontrado...
        </Text>
        <Text>Não foi possível encontrar serviços ofertados.</Text>
      </div>
    </div>
  );
}
