import { atom } from "jotai";

type FormAlterEmail = {
  email: string;
  password?: string;
};

const initialValuesAlterEmail = {
  email: "",
  password: "",
};

export const alterEmailAtom = atom<FormAlterEmail>(initialValuesAlterEmail);
