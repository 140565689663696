import { App, City, TermsOfUse, UF, Version, Location } from "@/store/useAppStore";
import {  Holder, Review, User } from "@/store/useUserStore";
import { Config } from "@/store/useConfigStore";
import { http } from "@/lib/axios";

export interface IAppRequest {
  frontend: {
    id: number;
    titulo: string;
    slug_name: string;
    version: string | null;
    url_playstore: string | null;
    url_appstore: string | null;
    linguagem: string | null;
    manutencao: boolean;

    cor_primaria: string | null;
    cor_secundaria: string | null;
    cor_texto_header: string | null;
    cor_fundo: string | null;
    cor_cabecario: string | null;
    cor_botoes: string | null;

    onesignal_app_id: string | null;
    onesignal_rest_api: string | null;
    socket_channel_name: string | null;

    splash: string;
    logo: string;
    icone: string;

    latitude: string | null;
    longitude: string | null;
    estado: { id: number; nome: string; sigla: string } | null;
    municipio: { id: number; nome: string } | null;

    link_termo_uso: string | null;
    versao_termo_uso: string | null;
  };
  versao: {
    link_zip: string;
    versao: string;
  } | null;
  configuracoes: Array<{
    id: number;
    slug: string;
    valor: string;
    tipo: string;
    configuracoes_frontend: { valor: string };
  }>;
  user: {
    id: number;
    email: string;
    cpf: string | null;
    name: string;
    imagem: string | null;
    avaliacao?: { rating: number; comment: string };
    fone: string | number | null;
    addresses: Array<{
      id: number;
      title: string;
      address: string;
      neighborhood: string;
      city: string;
      uf: string;
      postcode: string;
      street: string;
      number: string;
    }>;
    bairro: number | null;
    email_verified_at: string | null;
    link_termo_uso: string | null;
    versao_termo_uso: string | null;
    titulares: Array<{
      id: number;
      nome: string;
      email: string | null;
      cpf: string;
      foto: string | null;
      telefone: string | null;
      data_nascimento: string;
    }>;
  };
}

export async function getDataApp(appIdentifier: string) {
  try {
    const response = await http.get<IAppRequest>(`v3/auth_frontend/${appIdentifier}`, {
      params: {
        versao: "5",
      },
    });

    const data = response.data;

    let version: Version | null = null;

    if (data?.versao) {
      version = {
        url: data.versao.link_zip,
        version: data.versao.versao,
      };
    }

    let location: Location | null = null;
    let uf: UF | null = null;
    let city: City | null = null;

    if (data.frontend?.estado) {
      uf = {
        id: data.frontend.estado.id,
        name: data.frontend.estado.nome,
        slug: data.frontend.estado.sigla,
      };
    }

    if (data.frontend?.municipio) {
      city = {
        id: data.frontend.municipio.id,
        name: data.frontend.municipio.nome,
      };
    }

    location = {
      uf,
      city,
      longitude: data.frontend.longitude,
      latitude: data.frontend.latitude,
    };

    let termsOfUseApp: TermsOfUse | null = null;

    if (data.frontend?.link_termo_uso && data.frontend?.versao_termo_uso) {
      termsOfUseApp = {
        url: data.frontend.link_termo_uso,
        version: data.frontend.versao_termo_uso,
      };
    }

    const app: App = {
      id: data.frontend.id,
      name: data.frontend.titulo,
      slug: data.frontend.slug_name,
      language: data.frontend?.linguagem,
      maintenance: data.frontend.manutencao,
      urlPlaystore: data.frontend?.url_playstore,
      urlAppstore: data.frontend?.url_appstore,
      colors: {
        primary: data.frontend?.cor_primaria,
        secondary: data.frontend?.cor_secundaria,
        header: data.frontend?.cor_cabecario,
        textHeader: data.frontend?.cor_texto_header,
        buttons: data.frontend?.cor_botoes,
        background: data.frontend?.cor_fundo,
      },
      images: {
        splash: data.frontend.splash,
        logo: data.frontend.logo,
        logoHeader: data.frontend.icone,
      },
      socketChannel: data.frontend?.socket_channel_name,
      oneSignalAppId: data.frontend?.onesignal_app_id,
      oneSignalRest: data.frontend?.onesignal_rest_api,
      termsOfUse: termsOfUseApp,
      location,
      version,
    };

    const configs: Config[] = data.configuracoes.map((item) => ({
      id: item.id,
      slug: item.slug,
      value: item.configuracoes_frontend?.valor ?? item.valor,
      type: item.tipo,
    }));

    let user: User | null = null;

    if (data?.user) {
      const holders: Array<Holder> = data.user.titulares.map((item) => ({
         id: item.id,
          name: item.nome,
          email: item.email,
          cpf: item.cpf,
          birthdayDate: item.data_nascimento,
          imageUrl: item.foto,
          phone: item.telefone
      }));

      let review: Review | null = null;

      if (data.user?.avaliacao) {
        review = {
          rating: data.user.avaliacao.rating,
          comment: data.user.avaliacao.comment,
        };
      }

      let termsOfUse: TermsOfUse | null = null;

      if (data.user?.link_termo_uso && data.user?.versao_termo_uso) {
        termsOfUse = {
          url: data.user.link_termo_uso,
          version: data.user.versao_termo_uso,
        };
      }

      user = {
        id: data.user.id,
        name: data.user.name,
        email: data.user.email,
        cpf: data.user?.cpf,
        imageUrl: data.user?.imagem,
        phone: data.user?.fone ? data.user.fone.toString() : null,
        location: data.user?.bairro,
        addresses: data.user.addresses,
        verifiedEmail: !!data.user.email_verified_at,
        termsOfUse,
        holders,
        review,
      };
    }

    return { app, user, configs };
  } catch (err) {
    console.error(err);
    throw err;
  }
}
