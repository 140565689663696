import { useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Printer } from "@phosphor-icons/react";
import { Text } from "@astrolabe-ui/react";

import { Button, Loading } from "@/components";
import { BackWallet, Wallet } from "./components";
import { useWallet } from "./hooks/useWallet";
import { useAppStore } from "@/store/useAppStore";
import { formatCpf } from "@/utils/formats";

export function WalletPagePrint() {
  const { t } = useTranslation();
  const { search } = useLocation();
  const { id } = useParams<{ id: string }>();

  const query = new URLSearchParams(search);
  const holderId = Number(query.get("idTitular"));
  const hashWallet = String(query.get("hashCarteira"));

  const [appId, slug] = useAppStore((state) => [state.app?.id, state.app?.slug]);

  if (!appId || !slug) {
    throw new Error("Not loaded app!");
  }

  const { data: wallet, isLoading } = useWallet({
    appId,
    walletId: Number(id),
    holderId,
    hashWallet,
  });

  function handlePrintWallet() {
    window.print();
  }
  const urlWalletShare = useMemo(
    () =>
      `${import.meta.env.VITE_APP_WEB_URL
      }/${slug}/carteiras/${wallet?.id}?idTitular=${holderId}&hashCarteira=${hashWallet}`,
    [slug, hashWallet, holderId, wallet?.id],
  );

  return (
    <div className="mx-auto flex h-full w-full max-w-3xl flex-col items-center justify-center px-4 py-6 print:px-0">
      {isLoading || !wallet ? (
        <Loading size="lg" variant="secondary" />
      ) : (
        <>
          <div className="invisible h-0 w-full print:visible print:h-auto">
            <Wallet wallet={wallet} qrcodeUrl={urlWalletShare} />
          </div>

          <BackWallet fields={wallet.fields} className="hidden print:flex" />

          <div className="flex h-full w-full flex-col justify-between gap-6 after:flex-1 after:content-[''] print:hidden">
            <header className="flex-1 text-center">
              <Text asChild color="slate-900" size="lg" weight="semibold">
                <h1>{wallet.name}</h1>
              </Text>
              <Text asChild color="slate-600" size="lg" weight="medium">
                <h2 className="text-sm font-medium">{wallet.issuingBody}</h2>
              </Text>
            </header>

            <div className="flex flex-col items-center gap-8">
              <div className="flex flex-col text-center">
                <Text asChild color="slate-900" size="lg" weight="semibold">
                  <strong>{wallet.holder.name}</strong>
                </Text>
                <Text asChild color="slate-600" size="sm">
                  <strong>{formatCpf(wallet.holder.cpf)}</strong>
                </Text>
              </div>

              <Button size="lg" full className="max-w-sm print:hidden" onClick={handlePrintWallet}>
                <Printer />
                {t("wallets.Imprimir carteira")}
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
