import { SetStateAction, useState } from "react";
import { RadioGroup, RadioGroupItem, Text } from "@astrolabe-ui/react";

import { Modal } from "@/components";

export default function OrderModal({
  showModal,
  setShowOrderModal,
  updateAreasForOrder,
}: {
  showModal: boolean;
  setShowOrderModal: (value: SetStateAction<boolean>) => void;
  updateAreasForOrder: (order: "asc" | "desc") => void;
}) {
  const [orderAreasCategories, setOrderAreasCategories] = useState<"asc" | "desc">("asc");

  const handleSorteAlphabetic: (order?: "asc" | "desc") => void = (order = "asc") => {
    setOrderAreasCategories(order);
    updateAreasForOrder(order);
    setShowOrderModal(false);
  };

  return (
    <Modal onClose={() => setShowOrderModal(false)} showModal={showModal}>
      <div className="px-2">
        <RadioGroup>
          <RadioGroupItem
            {...(orderAreasCategories === "asc" && {
              checked: true,
            })}
            value="1"
            id="1"
            onClick={() => {
              handleSorteAlphabetic();
            }}
          >
            <Text color={orderAreasCategories === "asc" ? "slate-700" : "slate-300"}>
              Ordem alfabética: A-Z
            </Text>
          </RadioGroupItem>

          <RadioGroupItem
            {...(orderAreasCategories === "desc" && {
              checked: true,
            })}
            value="2"
            id="2"
            onClick={() => {
              handleSorteAlphabetic("desc");
            }}
          >
            <Text color={orderAreasCategories === "desc" ? "slate-700" : "slate-300"}>
              Ordem alfabética: Z-A
            </Text>
          </RadioGroupItem>
        </RadioGroup>
      </div>
    </Modal>
  );
}
