import { http } from "@/lib/axios";
import { IconPhosphorProps } from "@/components/CustomIcon";
import { clearContentHTML } from "@/utils/clearContentHTML";
import { adjustmentsIframe } from "@/utils/adjustmentsIframe";

type getFaqCategoryRequest = {
  id: number;
  title: string;
  icon: IconPhosphorProps;
  faq: {
    id: number;
    title: string;
  };
  questions: Array<{
    id: number;
    title: string;
    solution_mark: string;
  }>;
};

type getFaqCategoryParams = {
  appId?: number;
  faqId?: number;
  categoryId?: number;
  query: string;
};

type FaqCategory = {
  id: number;
  title: string;
  icon: IconPhosphorProps;
  questions: Array<{
    id: number;
    title: string;
    content: string;
  }>;
  faq: {
    id: number;
    title: string;
  };
};

export async function getFaqCategory({
  appId,
  faqId,
  categoryId,
  query,
}: getFaqCategoryParams): Promise<FaqCategory> {
  try {
    const response = await http.get<getFaqCategoryRequest>(
      `v3/${appId}/faq/${faqId}/categories/${categoryId}`,
      {
        params: {
          ...(query ? { search: query } : {}),
        },
      },
    );

    const data = response.data;

    const questions = data.questions.map((item) => ({
      id: item.id,
      title: query
        ? item.title.replaceAll(query, `<strong class="text-primary-500">${query}</strong>`)
        : item.title,
      content: query
        ? adjustmentsIframe(
            clearContentHTML(
              item.solution_mark.replaceAll(
                query,
                `<strong class="text-primary-500">${query}</strong>`,
              ),
            ),
          )
        : adjustmentsIframe(clearContentHTML(item.solution_mark)),
    }));

    const item = {
      id: data.id,
      title: data.title,
      icon: data.icon,
      questions,
      faq: {
        id: data.faq.id,
        title: data.faq.title,
      },
    };

    return item;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
