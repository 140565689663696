import { ComponentProps } from "react";
import { IonRippleEffect, IonSkeletonText } from "@ionic/react";
import { Text } from "@astrolabe-ui/react";

type CardItemProps = ComponentProps<"button"> & {
  title: string;
};

export function CardItem({ title, ...rest }: CardItemProps) {
  return (
    <button
      className="ion-activatable ripple-parent group relative flex flex-col items-center justify-center rounded-md border-thin border-slate-200 py-3 transition-colors disabled:bg-slate-100 data-[selected=true]:border-primary-500 data-[selected=true]:bg-primary-100"
      {...rest}
    >
      <Text
        weight="medium"
        size="sm"
        color="slate-700"
        className="group-data-[selected=true]:bg-primary-100"
      >
        {title}
      </Text>
      <IonRippleEffect className="text-slate-100/40" />
    </button>
  );
}

export function CardItemSkeleton() {
  return <IonSkeletonText animated className="h-[47px] w-full rounded-md" />;
}
