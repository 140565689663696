import { http } from "@/lib/axios";

import { Address, Endereco, ProtocolDetail } from ".";

type ProtocolRequest = {
  id: number;
  titulo: string;
  created_at: string;
  autor: number | null;
  categoriaServico: { titulo: string; fazer_pesquisa: boolean; pesquisa_pergunta_servico: boolean };
  status: { id: number; titulo: string };
  prazo_de_conclusao: string;
  tagCategoriaServico: { id: number; titulo: string; cor: string } | null;
  respostasCampos: Array<{
    nome_campo: string;
    resposta: Endereco | string;
    tipo: "integer" | "string" | "arquivo" | "data" | "imagem" | "location";
  }>;
  subCategoriaServico: {
    titulo: string;
  };
  avaliacoesAtendimento?: { id: number; protocolo_id: number } | null;
};

interface GetProtocolParams {
  appId?: number;
  protocolId: number;
}

export async function getProtocolByid({
  appId,
  protocolId,
}: GetProtocolParams): Promise<ProtocolDetail> {
  try {
    const data = (await http.get<ProtocolRequest>(`v3/${appId}/protocolos/${protocolId}`)).data;

    let tagCategoryService: { id: number; color: string; title: string } | null = null;

    if (data.tagCategoriaServico) {
      tagCategoryService = {
        id: data.tagCategoriaServico.id,
        color: data.tagCategoriaServico.cor,
        title: data.tagCategoriaServico.titulo,
      };
    }

    let avaliation = null;

    if (data.avaliacoesAtendimento) {
      avaliation = {
        id: data.avaliacoesAtendimento?.id,
        protocolo_id: data.avaliacoesAtendimento?.protocolo_id,
      };
    }

    return {
      id: data.id,
      authorId: data.autor,
      categoryService: {
        title: data.categoriaServico.titulo,
        enableAvaliation: data.categoriaServico.fazer_pesquisa,
        enableAvaliationService: data.categoriaServico.pesquisa_pergunta_servico,
      },
      created_at: data.created_at,
      title: data.titulo,
      status: {
        title: data.status.titulo,
        id: data.status.id,
      },
      avaliation,
      tagCategoryService,
      time_to_solution: data.prazo_de_conclusao,
      fields: data.respostasCampos
        .filter((item) => !!item.resposta)
        .map((rc) => ({
          name: rc.nome_campo,
          type: rc.tipo,
          value:
            rc.tipo === "location" && typeof rc.resposta === "object"
              ? ({
                  city: getCityName(rc.resposta.endereco_formatado),
                  uf: getUfName(rc.resposta.endereco_formatado),
                  lat: rc.resposta.lat,
                  lng: rc.resposta.long,
                  neighborhood: rc.resposta.bairro,
                  number: rc.resposta.numero,
                  postcode: rc.resposta.cep,
                  street: rc.resposta.logradouro,
                  textFormatted: rc.resposta.endereco_formatado,
                  detail: rc.resposta.complemento,
                } as unknown as Address)
              : String(rc.resposta),
        })),
      category: { title: data.subCategoriaServico.titulo },
    };
  } catch (error) {
    console.error(error);
    throw error;
  }
}

const getCityName = (address: string) => address.split(", ")[2].split(" - ")[0];

const getUfName = (address: string) => address.split(", ")[2].split(" - ")[1];
