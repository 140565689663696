import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { Text } from "@astrolabe-ui/react";

import { Button } from "@/components/Button";
import { http } from "@/lib/axios";
import { useAppId, useAppSlug } from "@/store/useAppStore";

import warningCircle from "@/assets/illustrations/Warning-circle.svg";
import { useHistory } from "react-router-dom";
import { useSetAtom } from "jotai";
import { alterEmailAtom } from "../../Register/atoms/AtomAlterEmail";

interface ModalConfirmationEmailProps {
  username: string;
  password: string;
  handleCloseModal: () => void;
}

export function ModalConfirmationEmail({
  username,
  password,
  handleCloseModal,
}: ModalConfirmationEmailProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const slug = useAppSlug();
  const setAlterEmailForm = useSetAtom(alterEmailAtom);

  const appId = useAppId();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (username: string) => {
      return http.get(`/v3/${appId}/usuarios/reenviar_email?username=${username}`);
    },
  });

  async function handleResendEmail() {
    await mutateAsync(username, {
      onSuccess: () => toast.success("E-mail enviado!", { position: "top-center" }),
      onError: () => toast.error("Não foi possível reenviar!", { position: "top-center" }),
    });
  }

  function handleAlterEmail() {
    history.replace(`/${slug}/auth/register/alter-email`);
    setAlterEmailForm({ email: username, password });
  }

  return (
    <div className="flex flex-col items-center">
      <header className="flex flex-col items-center gap-8">
        <img src={warningCircle} alt="Celular com ponto de exclamação no centro." />
        <Text asChild size="md" weight="medium" color="slate-700">
          <strong>{t("auth.Verifique seu e-mail")}</strong>
        </Text>
      </header>

      <div className="mt-8 flex flex-col text-center">
        <Text>
          {t("auth.Foi enviado um email de validação da sua conta para o email informado!")}
        </Text>
      </div>

      <footer className="mt-12 flex w-full flex-col gap-3">
        <Button
          variant="tertiary"
          size="lg"
          full
          rounded="md"
          type="button"
          onClick={handleAlterEmail}
          loading={isLoading}
          className="font-medium"
        >
          {t("auth.Alterar meu email")}
        </Button>

        <Button
          variant="secondary"
          size="lg"
          full
          rounded="md"
          onClick={handleResendEmail}
          loading={isLoading}
          className="border-slate-200 font-medium"
        >
          {t("auth.Reenviar e-mail de confirmação")}
        </Button>

        <Button
          variant="primary"
          size="lg"
          rounded="md"
          onClick={handleCloseModal}
          className="font-medium"
        >
          {t("general.Tentar novamente")}
        </Button>
      </footer>
    </div>
  );
}
