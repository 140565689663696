import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { CaretLeft } from "@phosphor-icons/react";
import { Text, TextInput } from "@astrolabe-ui/react";
import { FormProvider, useForm } from "react-hook-form";

import { ImageField } from "./Fields/Image";
import { Layout } from "@/templates/Layout";
import { HeaderButton, Header, Button } from "@/components";
import { TextInput as TextInputComponent } from "./Fields/Text";
import { Content } from "@/templates/Content";
import { useHistory, useParams } from "react-router-dom";
import {
  useAlterUserViewedReviewHolder,
  useCreateReviewHolder,
  useGetHolder,
} from "./hooks/useHolder";
import { useAppStore } from "@/store/useAppStore";

import errorIllustration from "@/assets/illustrations/error.svg";
import { formatCpf } from "@/utils/formats";
import { handleData } from "./util/handleDataUpdateHolder";
import { useUserViewed } from "./hooks/useUserViewed";
import { IonSkeletonText } from "@ionic/react";

export type UpdateHolder = {
  foto?: string;
  socialName?: string;
  email?: string;
  telefone?: string;
};

export const EditHolderPage = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const params = useParams<{ id: string }>();
  const holderId = Number(params.id)

  const fieldsText = [
    { holderField: "nome_social", name: "socialName", placeholder: t("holder.Nome Social") },
    { holderField: "telefone", name: "telefone", placeholder: t("holder.Telefone") },
    { holderField: "email", name: "email", placeholder: t("holder.Email") },
  ];

  const [appId] = useAppStore((state) => [state.app?.id]);

  const {
    data: holder,
    isError,
    isLoading,
    isSuccess,
    refetch,
  } = useGetHolder({ appId, id: holderId });

  const methods = useForm<UpdateHolder>();

  const { handleSubmit, reset } = methods;

  const { mutateAsync, isLoading: isLoadingReviewHolder } = useCreateReviewHolder({
    appId,
  });

  const { mutateAsync: mutateUserViewAsync, isLoading: isLoadingAlterUserViewedReviewHolder } =
    useAlterUserViewedReviewHolder({
      appId,
      id: holderId,
    });

  function handleNavigate() {
    history.goBack();
  }

  const onSubmit = (data: UpdateHolder) => {
    const { id_titular: idTitular, ...fields } = handleData({
      data,
      holder,
    });

    if (Object.keys(fields).length > 0) {
      return mutateAsync(
        { id_titular: idTitular, ...fields },
        {
          onError: (error) => {
            console.log(error);
            toast.error(t("holder.Houve um problema ao enviar seus dados para revisão"));
          },
          onSuccess: (res) => {
            console.log(res);
            reset();
            refetch();
            toast.success(t("holder.Dados enviados para revisão"));
          },
        },
      );
    }
    toast(t("holder.Nenhuma mudança identificada"));
  };

  useUserViewed({
    enable: !isLoadingAlterUserViewedReviewHolder,
    holder,
    handleUserViewedApproved,
  });

  async function handleUserViewedApproved() {
    await mutateUserViewAsync(undefined, {
      onSuccess: () => {
        refetch();
        toast.success(t("holder.Campos foram aceitos"), { id: "1" });
      },
      onError: (error) => {
        console.log(error);
      },
    });
  }

  const handleUserViewedField = (id?: number) => {
    mutateUserViewAsync(id, {
      onSuccess: () => refetch(),
      onError: (error) => {
        console.log(error);
      },
    });
  };

  return (
    <Layout>
      <Header>
        <div className="flex w-full items-center gap-4 after:flex-1 after:content-['']">
          <div className="flex-1">
            <HeaderButton icon={<CaretLeft weight="bold" />} onClick={handleNavigate} />
          </div>

          <Text size="md" weight="medium" leading="relaxed" className="truncate text-textHeader">
            {t("holder.Dados de cadastro")}
          </Text>
        </div>
      </Header>

      <Content>
        <div className="mx-auto flex min-h-full w-full max-w-3xl flex-col gap-4 px-4 py-6 ios:pb-2">
          {isLoading && !isError ? (
            <EditHolderSkeleton />
          ) : isError ? (
            <Error />
          ) : (
            holder &&
            isSuccess && (
              <FormProvider {...methods}>
                <form
                  className="flex flex-1 flex-col items-center gap-4"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <ImageField
                    data={holder}
                    handleUserViewedField={(id) => handleUserViewedField(id)}
                    isLoading={isLoadingReviewHolder}
                  />

                  <div className="flex w-full flex-col mt-6 gap-4">
                    <TextInput
                      placeholder={t("holder.Nome")}
                      value={holder.nome}
                      floating
                      readOnly
                      disabled
                    />

                    <TextInput
                      placeholder={t("holder.CPF")}
                      value={formatCpf(holder.cpf)}
                      floating
                      readOnly
                      disabled
                    />

                    {fieldsText.map((field) => (
                      <TextInputComponent
                        key={field.name}
                        data={holder}
                        holderField={field.holderField as "nome_social" | "email" | "telefone"}
                        name={field.name as "socialName" | "email" | "telefone"}
                        placeholder={field.placeholder}
                        handleUserViewedField={(id) => handleUserViewedField(Number(id))}
                      />
                    ))}
                  </div>

                  <div className="grow-1 mt-auto flex w-full flex-col justify-end">
                    <Button full size="lg" loading={isLoadingReviewHolder}>
                      Salvar
                    </Button>
                  </div>
                </form>
              </FormProvider>
            )
          )}
        </div>
      </Content>
    </Layout>
  );
};

const Error = () => {
  const { t } = useTranslation();
  return (
    <div className="w-ful flex h-full flex-col items-center justify-center gap-6 px-4 text-center">
      <img src={errorIllustration} alt="" />

      <div className="flex flex-col gap-2">
        <Text weight="medium" color="slate-700">
          {t("error.Desculpe, algo deu errado")}...
        </Text>
        <Text>{t("error.O nosso time de desenvolvedores esta resolvendo isso")}</Text>
      </div>
    </div>
  );
};

function EditHolderSkeleton() {
  return (
    <div className="mx-auto flex min-h-full w-full max-w-3xl flex-col gap-4 px-4 py-6 ios:pb-2">
      <div className="flex flex-col items-center gap-4">
        <IonSkeletonText animated className="h-24 w-24 rounded-full" />

        <div className="flex flex-col mt-6 gap-4 w-full">
          <IonSkeletonText animated className="w-full h-10 rounded-md" />
          <IonSkeletonText animated className="w-full h-10 rounded-md" />
          <IonSkeletonText animated className="w-full h-10 rounded-md" />
          <IonSkeletonText animated className="w-full h-10 rounded-md" />
          <IonSkeletonText animated className="w-full h-10 rounded-md" />
          <IonSkeletonText animated className="w-full h-10 rounded-md" />
        </div>
      </div>
      <IonSkeletonText animated className="mt-auto h-12 w-full rounded-md" />
    </div>
  )
}
