import { useState } from "react";
import { FiX } from "react-icons/fi";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { cpf } from "cpf-cnpj-validator";
import { useHistory } from "react-router";
import axios from "axios";

import { SelectRadioButton } from "./steps/SelectRadioButton";
import { ValidateCPF } from "./steps/ValidateCPF";
import { ConfirmData } from "./steps/ConfirmData";

import { ItemRadioButton } from "@/components/old/Forms/RadioButton";
import { Step, Steps } from "@/components/old/Steps";
import { Modal } from "@/components/old/Modal";
import { bindHolder } from "@/services/old/bind_holder";
import { getAppSlug } from "@/services/old/starter";
import { removeCaracteresNaoNumericos } from "@/utils/removeCaracteresNaoNumericos";
import { getContent } from "@/content/index";
import { updateUser } from "@/services/users";

import { Content, ModalContent } from "./styles";

interface ValidateDataProps {
  isOpen: boolean;
  onClose: (value: boolean) => void;
}

export interface NextCPFProps {
  optionsName: ItemRadioButton[];
  optionsDate: ItemRadioButton[];
}

export type ValidationData = {
  cpf: string;
  motherName: ItemRadioButton;
  birthDate: ItemRadioButton;
};

const CONTENT = getContent();

const stepsInitial = [
  { id: 0, title: CONTENT.VINCULAR_TITULAR.STEP_CPF, status: "current" },
  { id: 1, title: CONTENT.VINCULAR_TITULAR.STEP_NAME, status: "upcoming" },
  { id: 2, title: CONTENT.VINCULAR_TITULAR.STEP_DATE, status: "upcoming" },
  { id: 3, title: CONTENT.VINCULAR_TITULAR.STEP_CONFIRM, status: "upcoming" },
];

const validationSchemas = [
  yup.object().shape({
    cpf: yup
      .string()
      .required(CONTENT.VINCULAR_TITULAR.CPF_REQUIRED)
      .test("CPF válido", CONTENT.VINCULAR_TITULAR.CPF_INVALID, (value) => cpf.isValid(value!)),
  }),
  yup.object().shape({
    motherName: yup.object().shape({
      value: yup.string().required("Nome da mãe é obrigátorio"),
    }),
  }),
  yup.object().shape({
    birthDate: yup.object().shape({
      value: yup.string().required("Data de aniversário é obrigátorio"),
    }),
  }),
  yup.object(),
];

export function ValidateData({ isOpen, onClose }: ValidateDataProps) {
  const [currentStep, setCurrentStep] = useState(0);
  const [steps, setSteps] = useState<Step[]>(stepsInitial);

  const [loading, setLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenLinkedModal, setIsOpenLinkedModal] = useState(false);

  const [optionsName, setOptionsName] = useState<ItemRadioButton[]>([]);
  const [optionsDate, setOptionsDate] = useState<ItemRadioButton[]>([]);

  const currentValidationSchema = validationSchemas[currentStep];
  const methods = useForm<ValidationData>({
    resolver: yupResolver<ValidationData>(currentValidationSchema as any),
    mode: "onChange",
  });

  const history = useHistory();

  const handleSubmitForm: SubmitHandler<ValidationData> = async (data) => {
    try {
      setLoading(true);

      await bindHolder({
        cpf: removeCaracteresNaoNumericos(data.cpf),
        montherName: data.motherName.value,
        birthDate: data.birthDate.value,
      });

      onClose(false);

      updateUser();

      history.replace(`/${getAppSlug()}/link/success`);
    } catch (error) {
      console.log(error);

      if (axios.isAxiosError(error)) {
        if (error.response?.data.code === 400) {
          setIsOpenModal(true);
        }
        if (error.response?.data.code === 417) {
          setIsOpenLinkedModal(true);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  async function handleNextStep() {
    const isValid = await methods.trigger();
    if (!isValid) return;

    const indexCurrent = steps.findIndex((item) => item.id === currentStep);
    const newStep = steps[indexCurrent + 1].id;

    setCurrentStep(newStep);
    handleChangeStep(newStep, "next");
  }

  function handleBackStep() {
    const indexCurrent = steps.findIndex((item) => item.id === currentStep);
    const newStep = steps[indexCurrent - 1].id;

    setCurrentStep(newStep);
    handleChangeStep(newStep);
  }

  function handleChangeStep(currentStep: number, direction?: string) {
    if (direction === "next") {
      const newsSteps = steps.map((step) => ({
        ...step,
        status:
          step.id < currentStep ? "complete" : step.id === currentStep ? "current" : "upcoming",
      }));

      setSteps(newsSteps);
    } else {
      const newsSteps = steps.map((step) => ({
        ...step,
        status:
          step.id < currentStep ? "complete" : step.id === currentStep ? "current" : "upcoming",
      }));

      setSteps(newsSteps);
    }
  }

  function handleClose() {
    onClose(false);
    methods.reset();
    setCurrentStep(0);
    setSteps(stepsInitial);
  }

  async function handleNextCPF({ optionsName, optionsDate }: NextCPFProps) {
    setOptionsName(optionsName);
    setOptionsDate(optionsDate);
    await handleNextStep();
  }

  function handleBack() {
    handleClose();
    setIsOpenModal(false);
    history.push(`/${getAppSlug()}`);
  }

  return (
    <ModalContent
      isOpen={isOpen}
      initialBreakpoint={0.9}
      breakpoints={[0, 0.25, 0.5, 0.9]}
      onDidDismiss={handleClose}
    >
      <Content>
        <header>
          <h1>{CONTENT.VINCULAR_TITULAR.TITLE_MODAL}</h1>
          <button onClick={handleClose}>
            <FiX size={24} />
          </button>
        </header>

        <Steps steps={steps} currentStepId={currentStep} />

        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleSubmitForm)}>
            {currentStep === 0 && <ValidateCPF handleNext={handleNextCPF} onClose={handleClose} />}

            {currentStep === 1 && (
              <SelectRadioButton
                handleNext={handleNextStep}
                handleBack={handleBackStep}
                name="motherName"
                options={optionsName}
              />
            )}

            {currentStep === 2 && (
              <SelectRadioButton
                handleNext={handleNextStep}
                handleBack={handleBackStep}
                name="birthDate"
                options={optionsDate}
              />
            )}

            {currentStep === 3 && (
              <ConfirmData
                handleBack={handleBackStep}
                onCloseModal={handleClose}
                isLoading={loading}
              />
            )}
          </form>
        </FormProvider>
      </Content>

      <Modal
        isOpen={isOpenModal}
        onClose={handleBack}
        status="error"
        title={CONTENT.VINCULAR_TITULAR.CPF_BLOCKED}
        description={CONTENT.VINCULAR_TITULAR.CPF_BLOCKED_MESSAGE}
        textButton={CONTENT.VINCULAR_TITULAR.RETURN}
        onActionPrimary={handleBack}
      />

      <Modal
        isOpen={isOpenLinkedModal}
        onClose={handleBack}
        status="error"
        title={CONTENT.VINCULAR_TITULAR.INCORRECT_DATA}
        description={CONTENT.VINCULAR_TITULAR.INCORRECT_DATA_MESSAGE}
        textButton={CONTENT.VINCULAR_TITULAR.RETURN}
        onActionPrimary={handleBack}
      />
    </ModalContent>
  );
}
