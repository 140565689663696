import { Dispatch, SetStateAction, createContext, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";
import { FormProvider, useForm } from "react-hook-form";
import dayjs from "dayjs";
import { IonModal, useIonAlert } from "@ionic/react";
import { Button, Text } from "@astrolabe-ui/react";
import { Capacitor } from "@capacitor/core";
import { Geolocation } from "@capacitor/geolocation";
import { CaretLeft, Clock, Copy, X } from "@phosphor-icons/react";

import { Layout } from "@/templates/Layout";
import { Content } from "@/templates/Content";
import { Header, HeaderButton, Loading, ProgressiveBar } from "@/components";
import { Field, Subcategory } from "@/services/categoryServices";
import { useAppStore } from "@/store/useAppStore";
import { http } from "@/lib/axios";

import { SubCategories } from "../components/SubCategories";
import { Fields } from "../components/Form/Fields";
import { Review } from "../components/Review";

import { useSubcategory } from "../../hooks/useService";

import errorSVG from "@/assets/illustrations/error.svg";
import successSVG from "@/assets/illustrations/success.svg";

const FormContextProtocol = createContext(
  {} as { setBlocked: Dispatch<SetStateAction<boolean>>; blocked: boolean },
);

export const useFormContextProtocol = () => useContext(FormContextProtocol);

export const CreateProtocol = () => {
  const [geolocation, setGeolocation] = useState<{ lat: number; lng: number } | null>(null);

  const history = useHistory();
  const { t } = useTranslation();
  const { id, serviceId } = useParams<{ id: string; serviceId: string }>();

  const appId = useAppStore((state) => state.app?.id);
  const slug = useAppStore((state) => state.app?.slug);

  if (!appId || !slug) {
    throw new Error("Not loaded app");
  }

  const [presentAlert] = useIonAlert();

  const { data, isLoading } = useSubcategory(parseInt(serviceId), appId);

  const [fields, setFields] = useState<Array<Field>>([]);
  const [subcategory, setSubCategory] = useState<Subcategory>();
  const [showResultModal, setShowResultModal] = useState<boolean>(false);
  const [step, setStep] = useState<number>(0);
  const [loading, setLoading] = useState(isLoading);
  const [blocked, setBlocked] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [error, setError] = useState();

  const [protocolCreated, setProtocolCreated] = useState(false);
  const [protocolId, setProtocolId] = useState<string>();
  const [protocolTimeToResolve, setProtocolTimeToResolve] = useState<number>();

  const methods = useForm({ mode: "onChange" });

  function handleNavigate() {
    if (step === 0) history.goBack();
    else {
      setStep((current) => current - 1);
      data && data.fields.length <= 0 && setFields(data.fields);
    }
  }

  const onSubmit = (data: any) => {
    const responses = Object.fromEntries(Object.entries(data).filter(([, v]) => !!v));

    setLoading(true);

    const fieldsTypeDate = fields.filter((field) => field.type === "data");

    fieldsTypeDate.forEach((item) => {
      if (responses[item.id]) {
        responses[item.id] = dayjs(String(responses[item.id]), "DD/MM/YYYY").toISOString();
      }
    });

    if (geolocation?.lat && geolocation?.lng) {
      responses.latitude = geolocation.lat;
      responses.longitude = geolocation.lng;
    }

    http
      .post(`/v3/${appId}/protocolos`, responses)
      .then((res) => {
        setProtocolId(res.data.protocol.id.toString());
        setProtocolTimeToResolve(res.data.timeToSolution);
        setProtocolCreated(true);
        toast.success(t("general.Seu protocolo foi criado com sucesso!"));
      })
      .catch((err: any) => {
        console.log("err = ", err);
        setError(err.response.data.error);
        toast.error(t("error.Erro ao criar o protocolo"));
        setProtocolCreated(false);
      })
      .finally(() => {
        setShowResultModal(true);
        setLoading(false);
      });
  };

  const handleSubCategory = (subc: Subcategory) => {
    if (subc.message && subcategory?.id !== subc.id) {
      presentAlert({
        message: subc.message,
        buttons: ["OK"],
      });
    }
    data && setFields([...data.fields, ...subc.fields]);
  };

  const handleStep = () => {
    setStep((current) => current + 1);
  };

  const totalSteps = fields && fields.length > 0 ? 3 : 2;

  const handleCopy = async () => {
    protocolId && (await navigator.clipboard.writeText(protocolId));
    toast.success(t("general.Copiado com sucesso"));
  };

  useEffect(() => {
    if (!data?.integration && data && data.fields && step === 0) {
      setLoading(isLoading);
      setFields(data.fields);

      subcategory && handleSubCategory(subcategory);

      if (data?.subCategories.length === 1 && !subcategory) {
        methods.setValue("subcategory_id", data.subCategories[0].id);
        setSubCategory(data.subCategories[0]);
        handleSubCategory(data.subCategories[0]);
        handleStep();
      }
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, data?.integration, isLoading, step, subcategory]);

  useEffect(() => {
    const checkAndSetGeolocation = async () => {
      try {
        const permission = await Geolocation.requestPermissions();

        if (permission.location === "granted") {
          const position = await Geolocation.getCurrentPosition();
          setGeolocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        }
      } catch (error) {
        console.error("Error getting location", error);
      }
    };

    if (Capacitor.isNativePlatform()) {
      checkAndSetGeolocation();
    }
  }, []);

  return (
    <Layout>
      <Header variant={"secondary"} className="flex-wrap bg-slate-100 px-0 pb-0">
        <div className="h-4 w-full"></div>
        <div className="flex w-full items-center justify-between gap-4 overflow-hidden rounded-t-xl bg-white p-4">
          <HeaderButton variant={"secondary"} onClick={handleNavigate} icon={<CaretLeft />} />

          {!data?.integration ? (
            <div className="flex flex-1 justify-center">
              <ProgressiveBar loaded={step} total={totalSteps} />
            </div>
          ) : (
            <Text size="md" weight="medium" className="text-textHeader">
              {data?.title}
            </Text>
          )}

          <HeaderButton
            variant={"secondary"}
            onClick={() => history.replace(`/${slug}/categories-services/${id}/services`)}
            icon={<X />}
          />
        </div>
      </Header>

      <Content>
        <div className="mx-auto flex min-h-full w-full max-w-3xl flex-col items-center gap-4 px-4 py-6 ios:pb-2">
          {loading ? (
            <div className="flex min-h-full w-full items-center justify-center p-4">
              <Loading variant="secondary" size="md" />
            </div>
          ) : (
            <FormProvider {...methods}>
              <FormContextProtocol.Provider value={{ blocked, setBlocked }}>
                <form
                  onSubmit={methods.handleSubmit(onSubmit)}
                  className="flex h-full w-full flex-col gap-8"
                >
                  <div className="flex flex-wrap">
                    <div className="w-full">
                      <Text color="primary-500" weight="medium" size="md">
                        {data && t(data.title)}
                      </Text>
                    </div>
                    <div className="w-full">
                      <Text color="slate-700">{data && t(data.description)}</Text>
                    </div>
                  </div>
                  <div className="flex gap-4">
                    {data && data.subCategories && data.subCategories.length > 0 && step === 0 ? (
                      <SubCategories
                        onClick={(subcategory) => {
                          setSubCategory(subcategory);
                          handleSubCategory(subcategory);
                        }}
                        subcategories={data.subCategories}
                      />
                    ) : fields.length > 0 && step === 1 ? (
                      <Fields fields={fields} isValid={isValid} />
                    ) : (
                      data && (
                        <Review
                          fields={fields}
                          allowsOpenAnonymously={data.openAnonymous}
                          service={
                            data.subCategories.find(
                              (service) => service.id === methods.getValues("subcategory_id"),
                            ) as Subcategory
                          }
                        />
                      )
                    )}
                  </div>
                  <div className="flex w-full flex-1 flex-col before:flex-1 before:content-['']">
                    <Button
                      variant="primary"
                      size="lg"
                      full
                      disabled={blocked}
                      type={step === totalSteps ? "submit" : "button"}
                      onClick={() => {
                        if (!methods.watch("subcategory_id"))
                          return presentAlert({
                            message: t("general.Selecione um dos serviços acima."),
                            buttons: ["OK"],
                          });
                        methods.trigger().then((res) => {
                          step === 0 || res
                            ? step + 1 <= totalSteps && handleStep()
                            : setIsValid(false);
                        });
                      }}
                    >
                      {loading ? (
                        <Loading />
                      ) : (
                        t(step + 1 === totalSteps ? "general.Confirmar" : "general.Continuar")
                      )}
                    </Button>
                  </div>
                </form>
              </FormContextProtocol.Provider>
            </FormProvider>
          )}
          <IonModal
            isOpen={showResultModal}
            onDidDismiss={() => {
              setShowResultModal(false);
            }}
            tabIndex={0}
          >
            <div
              className="flex h-full flex-col flex-wrap items-center justify-between px-6 py-4"
              style={{ zIndex: 10 }}
            >
              <div className="flex w-full grow flex-col flex-wrap justify-center gap-4">
                <div className="flex w-full justify-center">
                  <img src={protocolCreated ? successSVG : errorSVG} alt="Status Image" />
                </div>

                <div className="flex w-full flex-wrap justify-center gap-2 px-10 ">
                  <Text weight="medium" color="slate-700" className="text-center">
                    {t(
                      protocolCreated
                        ? "general.Seu protocolo foi criado com sucesso!"
                        : "error.Falha ao finalizar o protocolo!",
                    )}
                  </Text>
                  {!protocolCreated && (
                    <Text weight="medium" color="slate-400" className="text-center">
                      {error}
                    </Text>
                  )}
                </div>

                {protocolCreated && (
                  <div className="flex w-full flex-wrap gap-2 ">
                    <div className="flex w-full flex-wrap justify-center gap-2">
                      <div className="flex w-full flex-wrap justify-center ">
                        <Text size="xs" color="slate-700" weight="medium">
                          {t("general.Código ID")}
                        </Text>
                      </div>
                      <div className="">
                        <Button
                          variant="tertiary"
                          type="button"
                          className="border-slate-200 tracking-[.5rem]"
                          onClick={() => handleCopy()}
                        >
                          {protocolId &&
                            protocolId.split("").map((c, index) => (
                              <Text className="mx-1" color="slate-800" weight="medium" key={index}>
                                {c}
                              </Text>
                            ))}
                          <Copy className="ml-1 text-slate-200" />
                        </Button>
                      </div>
                    </div>

                    <div className="flex w-full flex-wrap justify-center gap-6">
                      <Text color="slate-400" size="sm">
                        {t("general.Esse é o seu número de protocolo.")}
                      </Text>
                      {protocolTimeToResolve && protocolTimeToResolve > 0 ? (
                        <div className="flex w-full flex-wrap justify-center  gap-1">
                          <Text color="slate-400" size="sm">
                            {t("general.Prazo estimado para atendimento")}
                          </Text>
                          <div className="flex w-full flex-wrap items-center justify-center gap-1">
                            <Clock className="text-primary-500" />
                            <Text color="primary-500" size="sm">
                              {protocolTimeToResolve}h em {t("general.dias úteis")}
                            </Text>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                )}
              </div>

              <div className="flex w-full flex-wrap items-end self-end">
                <div className="flex w-full flex-wrap items-end gap-2 self-end">
                  <Button
                    size="lg"
                    className="w-full border-none "
                    variant="tertiary"
                    type="button"
                    onClick={() => {
                      setShowResultModal(false);
                      setStep(0);
                      history.goBack();
                    }}
                  >
                    {t("general.Retornar a página inicial")}
                  </Button>
                  <Button
                    size="lg"
                    className="w-full"
                    type="button"
                    onClick={() => {
                      setShowResultModal(false);
                      setStep((prevState) => prevState - 1);
                      protocolCreated && history.replace(`/${slug}/protocols/${protocolId}`);
                    }}
                  >
                    {t(
                      protocolCreated
                        ? "general.Detalhes do protocolo"
                        : "general.Tentar novamente",
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </IonModal>
        </div>
      </Content>
    </Layout>
  );
};
