import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IonRippleEffect, useIonRouter } from "@ionic/react";
import { CaretLeft } from "@phosphor-icons/react";
import { Box, Text } from "@astrolabe-ui/react";

import { formatCpf } from "@/utils/formats";

import { Layout } from "@/templates/Layout";
import { Content } from "@/templates/Content";
import { Header } from "@/components/Header";
import { Button, HeaderButton } from "@/components/index";
import { useAppSlug } from "@/store/useAppStore";
import { useHolders } from "@/store/useUserStore";

export function HolderWallet() {
  const { t } = useTranslation();
  const history = useIonRouter();
  const slug = useAppSlug();
  const holders = useHolders();

  function handleGoBack() {
    history.push(`/${slug}/home`);
  }

  function newLink() {
    history.push(`/${slug}/link`);
  }

  return (
    <Layout>
      <Header>
        <div className="flex w-full items-center after:flex-1 after:content-['']">
          <div className="flex-1">
            <HeaderButton icon={<CaretLeft weight="bold" />} onClick={handleGoBack} />
          </div>
          <Text size="lg" weight="medium" leading="relaxed" className="text-textHeader">
            {t("holder.Titulares")}
          </Text>
        </div>
      </Header>

      <Content>
        <div className="mx-auto flex min-h-full w-full max-w-2xl flex-col gap-4 px-4 py-6 ios:pb-2 flex-grow">

          {holders?.length === 0 ? (
            <div className="flex mt-6 flex-col gap-1 items-center">
              <Text weight="medium" size="md" color="slate-700" className="text-center">
                {t("holder.Sem titulares vinculados.")}
              </Text>
              <Text size="sm" weight="medium" className="text-center">
                {t("holder.Clique no botão abaixo para adicionar um novo vínculo!")}
              </Text>
            </div>
          )
            : null}

          {holders && holders?.length > 0 ?
            <div className="flex flex-col gap-3">
              <Text size="md" weight="medium" color="slate-700" className="line-clamp-1">
                {t("holder.Selecione o titular para ver as carteiras")}
              </Text>

              {holders?.map((holder) => (
                <Link to={`/${slug}/titulares/${holder.id}/carteiras`} key={holder.id}>
                  <Box
                    px={3}
                    py={3}
                    className="flex items-center gap-3 border-thin hover:cursor-pointer ion-activatable ripple-parent">
                    <div className="flex flex-col gap-0.5 overflow-hidden">
                      <Text size="md" weight="medium" color="slate-700" className="line-clamp-1">
                        {holder.name}
                      </Text>
                      <Text size="xs" weight="medium" color="slate-400" className="line-clamp-2">
                        {formatCpf(holder.cpf)}
                      </Text>
                    </div>
                    <IonRippleEffect className="text-slate-400" />
                  </Box>
                </Link>
              ))}
            </div>
            : null}

          <Button size="lg" className="mt-auto" onClick={newLink}>
            {t("holder.Adicionar novo vínculo")}
          </Button>
        </div>
      </Content>
    </Layout>
  );
}
