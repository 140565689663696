const ptbr = {
  GLOBAL: {
    SEARCH: "Pesquisar",
    MAINTENANCE: "Em manutenção",
    LOADING: "Carregando, aguarde um momento...",
    LOAD_MORE: "Carregar mais",
    DONE: "Pronto!",
    CONTINUE: "Continuar",
    SHARE: "Compartilhar",
    SHARE_MSG: "O link de acesso foi copiado para sua área de transferência.",
    NEEDTOBELOGED: "Você precisa criar ou entrar em sua conta para visualizar este conteúdo.",
    LOGOUT: "Sair",
    ACCEPT: "Aceito",
    PAGE_NOT_FOUND: "Página não encontrada!",
    TOUCH_TO_OPEN: "Toque para abrir",
    NOCONTENT: "Nenhum item cadastrado",
    CLOSE: "Fechar",
    OPEN: "Abrir",
    NEXT: "Avançar",
    PREV: "Voltar",
    SELECT: "Selecionar",
    FILES: "Arquivos",
    SELECT_FILE: "Selecionar arquivo",
    CHANGE_FILE: "Alterar arquivo",
    DOWNLOAD_LAST_SENT: "Baixar último enviado",
    MONTHS: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
  },
  STORE_EVALUATION: {
    TITLE: "O que esta achando do app?",
    RATE: "Avaliar",
    COMMENT_ON_STORE: "Enviar comentário na loja",
    SEND: "Enviar",
    CANCEL: "Cancelar",
    DONT_SHOW_AGAIN: "Não mostrar novamente",
  },
  FORM: {
    HEADER: "Pesquisas",
    ANSWER: "Responder",
    ANSWERED: "Respondido",
    ANSWERED_MSG: "Você já respondeu a este questionário",
    FULL_ANSWERED_MSG: "Todas as vagas já foram preenchidas",
    BT_RESERVE_ANSWER: "Responder vaga reserva",
    ALERT_NOT_ENOGHT_ANSWERS: "Você não atendeu o mínimo de questões necessário.",
    ALERT_TO_MUCH_ANSWERS: "O máximo de opções de resposta é ",
    ALERT_MISSING_ANSWERS: "Você não respondeu à todas as questões selecionadas.",
    MIN_SELECT_AMOUNT_P1: "Responda no mínimo à ",
    MIN_SELECT_AMOUNT_P2: " questões.",
  },
  USER: {
    MY_PROFILE: "Meu perfil",
    PROFILE_IMAGE: "Foto de perfil",
    PROFILE_UPDATE: "Atualizar perfil",
    CHANGE_PASSWORD: "Alterar senha",
    CHANGE_PASSWORD_MSG:
      "Um código de confirmação foi enviado para o seu e-mail. Por favor, para alterar a sua senha, informe o código de confirmação no espaço a baixo.",
    WELCOME_MSG: "Bem vindo ao",
    EMAIL: "E-mail",
    EMAILCPF: "E-mail/CPF",
    CPF: "CPF",
    PASSWORD: "Senha",
    PASSWORD_REPEAT: "Confirmar senha",
    FORGOTTEN_PASSWORD: "Esqueci minha senha",
    LOGIN: "Entrar",
    REGISTER: "Cadastrar",
    NAME: "Nome",
    ALERT_USER_UPDATE_TITLE: "Pronto!",
    ALERT_USER_UPDATE_MSG: "Seu perfil foi atualizado com sucesso.",
    ALERT_USER_UPDATE_BT: "Avançar",
    USER_DATA: "Dados do usuário",
    CHANGE_PASSWORD_TOKEN: "Código",
    CHANGE_PASSWORD_TOKEN_PLACEHOLDER: "Código de confirmação",
    CHANGE_PASSWORD_TOKEN_VALIDATION: "Validar código",
    CHANGE_PASSWORD_TOKEN_VALIDATION_TITLE: "Validar código",
    CHANGE_PASSWORD_TOKEN_VALIDATION_MSG:
      "Um código foi enviado para o seu e-mail. Por favor, para recuperar a sua senha, informe o código de confirmação a baixo.",
    CHANGE_PASSWORD_ERROR_NEED_EMAIL: "O e-mail é obrigatório",
    CHANGE_PASSWORD_ERROR_NEED_CPF: "O CPF é obrigatório",
    CHANGE_PASSWORD_ERROR_NEED_NAME: "O nome é obrigatório",
    CHANGE_PASSWORD_ERROR_NEED_TOKEN: "O código de confirmação é obrigatório",
    CHANGE_PASSWORD_ERROR_NEED_PASSWORD: "A senha é obrigatória.",
    CHANGE_PASSWORD_ERROR_NEED_CURRENT_PASSWORD: "É necessário informar a senha atual.",
    CHANGE_PASSWORD_ERROR_DIFERENTS_PASSWORD: "As senhas não são iguais.",
    CHANGE_PASSWORD_ERROR_EQUALS_TO_CURRENT: "A nova senha é igual a atual.",
    CHANGE_PASSWORD_CURRENT: "Senha atual",
    CHANGE_PASSWORD_NEW: "Nova senha",
    CHANGE_PASSWORD_REPEAT_NEW: "Confirmar nova senha",
    ALERT_PASSWORD_UPDATE_TITLE: "Pronto!",
    ALERT_PASSWORD_UPDATE_MSG: "Sua senha foi redefinida com sucesso.",
    ALERT_PASSWORD_UPDATE_REQUEST_MSG: "O código de validação foi enviado para",
    ALERT_PASSWORD_UPDATE_BT: "Avançar",
    REQUEST_CHANGE_PASSWORD_EMAIL_MSG1:
      "Por favor, informe o e-mail da conta que deseja recuperar a senha. Será enviado um código de confirmação para seu e-mail.",
    REQUEST_CHANGE_PASSWORD_EMAIL_MSG2:
      "Caso já possua um código de confirmação, utilize a opção 'Já possuo um código de confirmação'.",
    REQUEST_CHANGE_PASSWORD_I_HAVE_TOKEN: "Já possuo um código de confirmação",
    REQUEST_CANCEL_ACCOUNT: "Excluir Conta",
    ALERT_START_CANCEL_ACCOUNT_PROCESS:
      "Ao confirmar a exlusão de conta você perde acesso a conta, as incrições realizadas e acesso aos protocolos abertos! Lembre-se esse processo é irreversível! Caso queira prosseguir clique em confirmar abaixo.",
    ALERT_START_CANCEL_ACCOUNT_PROCESS_TITLE: "Excluir conta!",
    STOP_CANCEL_ACCOUNT_PROCESS:
      "Caso queira cancelar o processo de exclusão clique no botão confirmar abaixo!",
    STOP_CANCEL_ACCOUNT_PROCESS_TITLE: "Cancelar processo de exclusão!",
    CREDENTIALS_DONT_MATCH: "Credenciais não conferem!",
    VERIFY_EMAIL: "É necessário verificar o email!",
    VERIFY_EMAIL_TITLE: "Verificação de Email",
    RESEND_EMAIL:
      "É necessário verificar o email para fazer o login! Caso precise clique no botão abaixo para reenviar o email de confirmação!",
    CPF_OR_EMAIL_ALREADY_REGISTERED: "O cpf ou o email já estão em uso nesta aplicação!",
    CPF_OR_EMAIL_ALREADY_REGISTERED_TITLE: "Cpf ou email já em uso!",
    GUEST: "Entrar como convidado",
  },

  USERTERMS: {
    TITLE: "Termos de uso",
    ACCEPT_MSG: "Li e aceito os termos de uso.",
    ALERT_FAIL: "Atenção!",
    ALERT_FAIL_MSG: "Você precisa aceitar os termos antes de proceguir.",
  },

  LOCATION: {
    YOU_ARE_HERE: "Você está aqui",
    TITLE: "Localização",
    LOADING: "Carregando localização...",
    ROAD: "Rua",
    NEIGHBORHOOD: "Região",
    POSTCODE: "CEP",
    NUMBER: "Número",
    ESTATE: "Estado",
    UNKONW_LOCATION: "Localização indefinida",
    CLOSE_MAP: "Fechar mapa",
    OPEN_MAP: "Abrir mapa",
    SEARCH_HERE: "Pesquise aqui",
    SEARCH: "Buscar",
    ADDRESS: "Endereço",
    ERROR_NEED_NEIGHBORHOOD: "A região é obrigatória",
    CHANGE_ADDRESS_MSG: "* Clique no texto do endereço para altera-lo.",
    ALERT_FAIL_TITLE: "Não foi possível encontrar o enderço solicitado.",
    ALERT_FAIL_MSG:
      "Por favor, verifique o endereço informado, ou busque por uma rua próxima fazendo o ajuste manual no mapa.",
    ALERT_SELECT_LOCATION_TITLE: "Qual localização deseja utilizar?",
    DONE: "Pronto",
    ALERT_FAIL_MSG_LOCATION:
      "A localização selecionada fica fora da área de atuação do aplicativo.",
  },

  IMAGE: {
    FILE_FAIL: "Arquivo inválido",
  },

  // news page
  NEWS: {
    HEADER_TITLE: "Notícias",
    PAGE_TITLE: "Últimas notícias",
    SEE_ALL: "Todas as notícias",
  },

  // events page
  EVENTS: {
    HEADER_TITLE: "Eventos",
    HEADER_ALERT: "Ocorreu um erro ao carregar os eventos",
    MY_EVENTS_HEADER_TITLE: "Meus eventos",
    HEADER_TITLE_OPEN: "Evento",
    PAGE_TITLE: "Últimos eventos",
    SUBSCRIBE: "Adicionar à agenda",
    UNSUBSCRIBE: "Remover da agenda",
    RELATED: "Eventos relacionados",
  },

  // Informations
  INFORMATION: {
    HEADER_TITLE: "Informações",
    HEADER_TITLE2: "Informação",
    NEXT: "Próximo",
    BACK: "Anterior",
    DETAIL: "Detalhar",
    FILTER: "Filtre por tag",
  },

  // service page
  SERVICES: {
    HEADER_TITLE: "Serviços",
    PAGE_TITLE: "Tabela de serviços",
    MY_PROTOCOLS_HEADER_TITLE: "Meus protocolos",
  },
  // protocol page
  PROTOCOLS: {
    PROTOCOL: "Protocolo",
    HEADER_TITLE: "Protocolos",
    PAGE_TITLE: "Meus protocolos",
    OPEN_CHAT: "Visualizar mensagens",
    INDENTIFIER: "Identificador",
    PLATE: "Placa",
    STATE: "Situação",
    ADDRESS: "Endereço",
    CATEGORY: "Categoria",
    CREATE_AT: "Criado em",
    MESSAGES: "Mensagens",
    OBSERVATIONS: "Observações",
    SELECT_CATEGORY: "Selecionar opção",
    REGISTER: "Abrir solicitação",
    IMAGE: "Imagem da solicitação",
    ERROR_CREATE_TITLE: "Falha",
    ERROR_CREATE_NEED_WAIT:
      "Você cadastrou uma solicitação recentemente nesta categoria. Por favor, aguarde e tente novamente.",
    ERROR_SELECT_LOCATION: "Selecione a localização",
    ERROR_SELECT_CATEGORY: "Selecione a opção",
    ERROR_SELECT_IMAGE: "Selecione uma imagem",
    ERROR_MESSAGE: "A mensagem é obrigatória",
    ADDRESS_PLACEHOLDER: "Nome da rua e número",
    VALUE: "Valor",
    CANT_ACCESS:
      "Não é possivel consultar este protocolo, Código invalido ou você não possui permissão para visualizar o mesmo.",
    ETERNAL_SERVICE: "Acessar página do serviço",
    HORARIOATENDIMENTO:
      "Este serviço não encontra-se em horário de atendimento, mas você pode seguir com o cadastro.\n\nAssim que possível um atendente dará sequência a sua solicitação.",
    NAME: "Nome",
    ERROR_NAME: "O nome é obrigatório",
    WITHOUT_IDENTIFICATION: "Sem identificação",
    FIELD_REQUIRED: "Este campo é obrigatório.",
  },

  // loading alert dialog
  ALERT_DIALOG: {
    TITTLE: "Carregando, aguarde um momento...",
    CHAT_ERROR: "Não foi possível alcançar o servidor.",
  },

  // chat componte
  CHAT: {
    INPUT_TEXT_HINT: "Digite...",
    FILE: "Arquivo",
  },

  FEIRINHA: {
    HEADER_TITLE: "Comercio Local",
    EDIT_PRODUCT: "Editar item",
    EDIT_SELLER: "Editar perfil do comercio",
    CATEGORY: "Categoria",
    CHANGE_CATEGORY: "Alterar categoria",
    SELECT_CATEGORY: "Selecionar categoria",
    ALL_CATEGORIES: "Todas categorias",
    PRODUCTS: "Items",
    DESCRIPTION: "Descrição",
    SHARE: "Compartilhar",
    SELLER: "Vendedor",
    SELLER_REGISTER: "Cadastrar vendedor",
    PHONE: "Telefone",
    PRODUCT_REGISTER: "Cadastrar item",
    DELETE_REGISTER: "Excluir item",
    DELETE_REGISTER_MSG: "Você tem certeza que deseja excluir o item?",
    ERROR_NEED_PHONE: "O telefone é obrigatório",
    UNITY: "Unidade",
    VALUE: "Preço",
    SELECT_MODULE: "Selecionar feirinha",
    MODULE: "Comercio Local",
    ERROR_NEED_NAME: "O nome é obrigatório",
    ERROR_NEED_DESCRIPTI: "A descrição é obrigatória",
    ERROR_NEED_UNITY: "A unidade é obrigatória",
    ERROR_NEED_VALUE: "O valor é obrigatório",
    ERROR_NEED_MODULE: "O modulo é obrigatória",
    ERROR_NEED_CATEGORY: "A categoria é obrigatório",
    SEND_TO_VISIBLE: "Tornar visível",
    SEND_TO_REVISION: "Enviar para revisão",
    SEND_TO_HIDE: "Tornar oculto",
    IN_REVISION: "Em revisão",
    HIDE_BY_SELLER: "Oculto pelo vendedor",
    FAILED_IN_REVISION: "Reprovado na revisão",
    WARNING_TITTLE: "Aviso",
    WARNING_TEXT: "Item em revisão, em breve será liberado!",
  },

  CAMERA: {
    TITLE: "Camera",
    MODULES: "Modulos",
    TRY_AGAIN: "Tirar outra",
    USE_THIS: "Usar imagem",
    COMMENT: "Comentário",
    SEND: "Enviar",
    WARNING_TITTLE: "Aviso",
    WARNING_TEXT: "Imagem em revisão, em breve será liberada!",
    WARNING_CLOSE: "Fechar",
    ADD_DESCRIPTION: "Adicione uma descrição",
    DESCRIPTION: "Descrição",
  },
  GET_IMAGE: {
    MESSAGE: "Selecione a fonte da imagem",
    CAMERA: "Camera",
    FILE: "Arquivos",
  },

  VINCULAR_TITULAR: {
    TITLE: "Vinculação de Titulares",
    TITLE_MODAL: "Vincular titular",
    TITLE_MENU: "Vincular-se a Titular",
    VALIDATE: "Validação de dados",
    COMPLETE: "Complete as próximas etapas para concluir a vinculação de titulares.",
    CHECKOUT: "Confira suas informações antes de confirmar, você terá apenas uma tentativa.",
    START: "Iniciar",
    RETURN: "Retornar para página inicial",
    VALIDATE_SUCCESS: "Validação de titular realizada com sucesso.",
    ORIENTATION:
      "Pronto! Sua conta no aplicativo foi vinculada a um perfil titular. Agora você tem direito aos benefícios das instituições que você é relacionado.",
    CONTACT_MESSAGE: "Entre em contato com os orgãos responsáveis para mais informações.",
    STEP_CPF: "Digite o número do seu CPF",
    STEP_NAME: "Selecione o nome da sua mãe",
    STEP_DATE: "Selecione a sua data de nascimento",
    STEP_CONFIRM: "Confirme os dados",
    CPF_REQUIRED: "CPF é obrigátorio",
    CPF_INVALID: "CPF inválido",
    CPF_BLOCKED: "CPF bloqueado",
    CPF_BLOCKED_MESSAGE:
      "Entre em contato com a instituição responsável para realizar o desbloqueio.",
    CPF_NOT_FOUND: "CPF não encontrado",
    CPF_NOT_FOUND_MESSAGE:
      "O número de CPF não possui vínculo com nenhuma instituição cadastrada no aplicativo.",
    TRY_AGAIN: "Tentar novamente",
    ADVANCE: "Avançar",
    CONCLUDE: "Concluir",
    CPF: "CPF",
    MOTHER_NAME: "Nome da mãe",
    BIRTH_DATA: "Data de nascimento",
    CONFIRM: "Confirmar dados",
    RETURN_STEP: "Retornar",
    STEP: "Passo",
    CPF_UPDATED: "Atualizar dados",
    CPF_UPDATED_MESSAGE:
      "Deseja incluir o CPF informado aos dados do seu perfil da aplicação? Se sim, clique no botão correspondente abaixo.",
    UPDATED: "Atualizar",
    NOT: "Não, obrigado",
    CPF_UPDATED_ERROR: "Erro ao atualizar perfil",
    CPF_UPDATED_SUCCESS: "Perfil atualizado",
    CPF_IN_USE: "CPF em uso",
    CPF_IN_USE_MESSAGE: "Já existe um usuário vinculado ao titular desse CPF!",
    INCORRECT_DATA: "Dados incorretos!",
    INCORRECT_DATA_MESSAGE: "Algum dos dados passados está incorreto",
  },
  TITULAR: {
    TITULARIDADE: "Titularidade",
  },
  CARTEIRA: {
    TITLE_MENU: "Carteiras",
  },
};

export default ptbr;
