import { http } from "@/lib/axios";
import { removeAcentAndLowerCaseString } from "@/utils/filterQueryString";

type ServiceResponse = {
  id: number;
  titulo: string;
  descricao?: string;
  imagem?: string;
  integracao: string;
  abrir_externo: boolean;
  abrir_anonimo: boolean;
  area_categoria: {
    id: number;
    titulo: string;
    imagem: string;
    icon: string;
  };
};

type getServicesBySearchParams = {
  appId: number;
  query: string;
  aggregatorOnly?: string | null;
  aggregatorExcept?: string | null;
};

export async function getServicesBySearch({
  appId,
  query,
  aggregatorOnly,
  aggregatorExcept,
}: getServicesBySearchParams) {
  try {
    const response = await http.get<Array<ServiceResponse>>(`/v3/${appId}/categoriaServicos`, {
      params: {
        orderby: "titulo:asc",
        ...(aggregatorOnly ? { aggregator_only: aggregatorOnly } : {}),
        ...(aggregatorExcept ? { aggregator_except: aggregatorExcept } : {}),
      },
    });

    const services = response.data.map((category) => ({
      id: category.id,
      title: category.titulo,
      description: category.descricao,
      isPublic: category.abrir_anonimo,
      toBlank: category.abrir_externo,
      integration: category.integracao,
      image: category.imagem,
      areaCategory: {
        id: category.area_categoria.id,
        title: category.area_categoria.titulo,
        image: category.area_categoria.imagem,
        icon: category.area_categoria.icon,
      },
    }));

    if (!query) {
      return services;
    }

    const queryFormatted = removeAcentAndLowerCaseString(query);

    return services.filter(
      (item) =>
        removeAcentAndLowerCaseString(item.title).includes(queryFormatted) ||
        removeAcentAndLowerCaseString(item.areaCategory.title).includes(queryFormatted),
    );
  } catch (error) {
    console.error(error);
    throw error;
  }
}
