import { useMutation } from "@tanstack/react-query";
import { unlinkHolder } from "@/services/holder/unlinkHolder";

interface useUnlinkHoldersParams{
  appId?: number;
  holderId: number | null;
}

export function useUnlinkHolders(){
  return useMutation({
    mutationFn: ({appId, holderId}: useUnlinkHoldersParams) => {
      return unlinkHolder({appId, holderId})
    }
  })
}