import { http } from "@/lib/axios";

type GetNewMessagesParams = {
  appId: number;
};

export async function getNewMessages({ appId }: GetNewMessagesParams) {
  try {
    const response = await http.get<Array<{ protocolo: number }>>(
      `v2/${appId}/usuarios/mensagensNaoLidas`,
    );

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
