import { useMemo, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { isAxiosError } from "axios";
import { Clipboard } from "@capacitor/clipboard";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Text } from "@astrolabe-ui/react";
import { CaretLeft, Export, Link, Printer, Share, XCircle } from "@phosphor-icons/react";

import { Layout } from "@/templates/Layout";
import { Content } from "@/templates/Content";
import { Header, HeaderButton, Button, Modal } from "@/components";
import { Wallet, Infos, Notices } from "./components";
import { useAppStore } from "@/store/useAppStore";
import { useAuthStore } from "@/store/useAuthStore";
import { useWallet } from "./hooks/useWallet";
import { openPdfDocument } from "@/utils/openPdfDocument";
import { http } from "@/lib/axios";
import { useUserStore } from "@/store/useUserStore";

type WalletErrorResponse = {
  type: string;
};

export function WalletPage() {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { search } = useLocation();

  const query = new URLSearchParams(search);
  const holderId = Number(query.get("idTitular"));
  const hashWallet = String(query.get("hashCarteira"));

  const [appId, slug] = useAppStore((state) => [state.app?.id, state.app?.slug]);
  const isLogged = !!useAuthStore((state) => state.token);
  const userId = useUserStore((state) => state.user?.id);

  if (!appId || !slug) {
    throw new Error("Not loaded app!");
  }

  const [isOpenModal, setIsOpenModal] = useState(false);

  const {
    data: wallet,
    isLoading,
    isError,
    error,
  } = useWallet({
    appId,
    walletId: Number(id),
    holderId,
    hashWallet,
  });

  const { mutateAsync, isLoading: isExportingDocument } = useMutation({
    mutationFn: () => generateDocument(),
  });

  async function generateDocument() {
    try {
      const response = await http.get(
        `/v2/${appId}/carteiras/${wallet?.id}/emitir_doc/${wallet?.holder.id}`,
        { responseType: "blob" },
      );

      const holderName = wallet?.holder.name;

      const filename = `${holderName} - ${new Date().getTime()}.pdf`;

      await openPdfDocument(response.data, filename);
    } catch (error) {
      console.error(error);
    }
  }

  function handleExportDocument() {
    mutateAsync(undefined, {
      onSuccess: () => handleCloseModal(),
      onError: () => toast.error("Erro ao gerar documento."),
    });
  }

  function handlePrintWallet() {
    const url = `${import.meta.env.VITE_APP_WEB_URL
      }/${slug}/carteiras/${id}/print?idTitular=${holderId}&hashCarteira=${hashWallet}`;

    window.open(url, "_system");

    handleCloseModal();
  }

  async function handleCopyUrl(url: string) {
    try {
      await Clipboard.write({
        string: url,
      });

      toast.success("Link copiado!");

      handleCloseModal();
    } catch (err) {
      console.error(err);
      toast.error("Erro ao copiar!");
    }
  }

  function isWalletInvalid(err: unknown) {
    return (
      isAxiosError<WalletErrorResponse>(err) &&
      err.response &&
      err.response.data.type === "invalida"
    );
  }

  function handleCloseModal() {
    setIsOpenModal(false);
  }

  function handleGoBack() {
    history.goBack();
  }

  const urlWalletShare = useMemo(
    () =>
      `${import.meta.env.VITE_APP_WEB_URL
      }/${slug}/carteiras/${wallet?.id}?idTitular=${holderId}&hashCarteira=${hashWallet}`,
    [slug, hashWallet, holderId, wallet?.id],
  );

  return (
    <Layout>
      <Header className="print:hidden">
        <div className="flex w-full items-center after:flex-1 after:content-['']">
          <div className="flex-1">
            {isLogged ? (
              <HeaderButton icon={<CaretLeft weight="bold" />} onClick={handleGoBack} />
            ) : null}
          </div>

          <Text size="md" weight="medium" leading="relaxed" className="text-textHeader">
            {t("wallets.Carteira")}
          </Text>
        </div>
      </Header>

      <Content>
        <div className="mx-auto flex min-h-full w-full max-w-3xl flex-col gap-4 px-4 py-6 ios:pb-2">
          {isLoading || !wallet ? (
            <WalletSkeleton />
          ) : isError && isWalletInvalid(error) ? (
            <WalletInvalid />
          ) : (
            <div className="flex flex-1 flex-col gap-6">
              <Wallet wallet={wallet} qrcodeUrl={urlWalletShare} />

              <Notices wallet={wallet} />

              <Infos fields={wallet.fields} />

              {userId && wallet.userId && wallet.status !== "suspenso" ? (
                <footer className="mt-auto flex print:hidden">
                  <Button full size="lg" onClick={() => setIsOpenModal(true)}>
                    <Share size={20} />
                    {t("wallets.Exportar")}
                  </Button>
                </footer>
              ) : null}
            </div>
          )}
        </div>

        <Modal showModal={isOpenModal} onClose={handleCloseModal} classname="print:hidden">
          <div className="space-y-3">
            <Button full size="lg" variant="tertiary" onClick={() => handleCopyUrl(urlWalletShare)}>
              <Link size={20} />
              {t("wallets.Copiar link da carteira")}
            </Button>

            <Button full size="lg" variant="secondary" onClick={handlePrintWallet}>
              <Printer size={20} />
              {t("wallets.Imprimir carteira")}
            </Button>

            <Button full size="lg" loading={isExportingDocument} onClick={handleExportDocument}>
              <Export size={20} />
              {t("wallets.Exportar documento")}
            </Button>
          </div>
        </Modal>
      </Content>
    </Layout>
  );
}

function WalletSkeleton() {
  return (
    <div className="flex flex-1 flex-col">
      <div className="mb-8 flex h-48 w-full flex-col justify-between gap-2 rounded-2xl border-2 border-slate-200/60 bg-slate-100 px-4 py-5">
        <div className="flex justify-between">
          <div className="h-16 w-16 rounded border bg-slate-200/60"></div>
          <div className="h-14 w-14 rounded bg-slate-200/60"></div>
        </div>

        <div className="flex items-end justify-between">
          <div className="flex flex-1 flex-col gap-1">
            <div className="h-6 w-2/3 rounded-full bg-slate-200/60 leading-6"></div>
            <div className="h-4 w-1/3 rounded-full bg-slate-200/40"></div>
            <div className="h-4 w-1/3 rounded-full bg-slate-200/40"></div>
          </div>

          <div className="h-9 w-9 flex-shrink-0 rounded bg-slate-200/60"></div>
        </div>
      </div>

      <div className="flex flex-col gap-4">
        <div className="h-6 w-1/3 rounded-full bg-slate-200/60" />
        <ul className="space-y-2">
          {Array.from(Array(4).keys()).map((index) => (
            <div
              key={index}
              className="h-[68px] rounded-xs border-default border-transparent bg-slate-100"
            />
          ))}
        </ul>
      </div>
    </div>
  );
}

function WalletInvalid() {
  const { t } = useTranslation();

  return (
    <div className="flex min-h-screen flex-col items-center justify-center px-4 antialiased">
      <XCircle size={40} className="text-red-600" />

      <strong className="mb-4 mt-6 text-2xl font-medium text-black">
        {t("wallets.Carteira inválida")}
      </strong>

      <p className="text-center text-base text-gray-600">
        {t("wallets.sNão foi possível autenticar os dados da carteira. Informe ao solicitante.")}
      </p>
    </div>
  );
}
