import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Button, Text } from "@astrolabe-ui/react";

import { Layout } from "@/templates/Layout";
import { Content } from "@/templates/Content";
import { useAppSlug } from "@/store/useAppStore";
import { useConfig } from "@/hooks/useConfig";

import sucesss from "@/assets/illustrations/success-circle.svg";

import { useAtom } from "jotai";
import { alterEmailAtom } from "../atoms/AtomAlterEmail";

export function RegisterUserSuccess() {
  const { t } = useTranslation();
  const history = useHistory();
  const [alterEmail] = useAtom(alterEmailAtom);

  const slug = useAppSlug();

  const triggerEmailEnabled = useConfig({
    label: "email.disparar",
  });

  function handleNavigate() {
    history.replace(`/${slug}/auth/login`);
  }

  function handleAlterEmail() {
    history.replace(`/${slug}/auth/register/alter-email`);
  }

  return (
    <Layout>
      <Content>
        <div className="mx-auto flex min-h-full w-full max-w-3xl flex-col items-center gap-8 px-4 py-6 ios:pb-2">
          <header className="mt-[30%] flex flex-col items-center gap-12">
            <p className="text-md font-medium text-[#2563EB]">{t("auth.Cadastro")}</p>
            <div className="flex flex-col text-center">
              <Text asChild size="md" weight="medium" color="slate-700">
                <strong>{t("auth.Você está quase lá!")}</strong>
              </Text>
              <Text size="sm" weight="normal">
                {t("auth.Sua conta foi criada com sucesso!")}
              </Text>
            </div>
            <img src={sucesss} alt="Blocos com ícones de check." className="max-w-[80%]" />
          </header>

          <div className="flex flex-col gap-6 text-center">
            {!triggerEmailEnabled ? (
              <Text>
                {t(
                  "auth.Sua conta foi criada. Agora você possui um perfil e pode usar todas as funcionalidades do app.",
                )}
              </Text>
            ) : null}
            <Text size="md" color="slate-700" weight="medium">
              {triggerEmailEnabled ? (
                <>
                  {`${t(
                    "auth.Confira sua caixa de entrada e clique no link enviado para confirmar seu e-mail",
                  )}: ${alterEmail.email}`}
                </>
              ) : (
                <>
                  {t(
                    "auth.Retorne a página de acesso, realize seu primeiro login para entrar no aplicativo.",
                  )}
                </>
              )}
            </Text>
          </div>

          <footer className="mt-auto flex w-full flex-col gap-3">
            <Button
              variant="secondary"
              size="lg"
              full
              rounded="md"
              onClick={handleAlterEmail}
              type="button"
              className="font-medium"
            >
              {t("auth.Meu e-mail está incorreto")}
            </Button>

            <Button
              variant="primary"
              size="lg"
              rounded="md"
              full
              className="font-medium"
              onClick={handleNavigate}
            >
              {t("auth.Acessar minha conta")}
            </Button>
          </footer>
        </div>
      </Content>
    </Layout>
  );
}
